import React, {useCallback, useEffect, useState} from "react";
import TitleText from "../../../components/assets/text/TitleText";
import {useDispatch, useSelector} from "react-redux";
import {
    makeSelectAddressGouvFrom,
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import TextInput from "../../../components/assets/FormAssets/TextInput";
import SelectorInput from "../../../components/assets/FormAssets/selector/SelectorInput";
import RegularBtn from "../../../components/assets/button/RegularBtn";
import {ButtonState, State, Theme, UnitType} from "../../../../../../config/app-config";
import {Segment} from "../../../../../../corelogic/models/types/new-simulation/form/Segment";
import {ProductionType} from "../../../../../../corelogic/models/types/new-simulation/form/ProductionType";
import {newSimulationActions} from "../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {InjectionPoint} from "../../../../../../corelogic/models/types/new-simulation/form/InjectionPoint";
import InjectionPointsTable from "../../table/InjectionPointsTable";
import DescriptionText from "../../../components/assets/text/DescriptionText";
import LoadCurveInput from "../LoadCurveInput";
import {PhotovoltaicModel} from "../../../../../../corelogic/models/types/new-simulation/form/PhotovoltaicModel";
import {useSnackbar} from "notistack";
import {v4 as uuidv4} from 'uuid';
import PVGISInclinaisonTooltip from "../../tooltip/PVGISInclinaisonTooltip";
import PVGISOrientationTooltip from "../../tooltip/PVGISOrientationTooltip";
import {
    selectIsLoadingNewCurve,
    selectIsLoadingSimulationForm,
} from "../../../../../../corelogic/usecases/loader/loaderSelector";
import {push} from "connected-react-router";
import Notification from "../../paper/Notification";
import {ChooseTVA} from "../consumption-point-form/form/ChooseTVA";
import LeafletMap from "../../map/leaflet-map/LeafletMap";
import {MapIconSolid} from "../../../components/assets/icon/MapIcon";
import SingleBtnToggle from "../../../components/assets/toggle/SingleBtnToggle";
import TypologyPointAddressInput from "./TypologyPointAddressInput";
import {
    makeIsInjectionPointNameValid,
    makeIsMaximumProductionPowerValid,
    makeIsPriceValid
} from "../../../../../../corelogic/usecases/new-simulation/ValidatorsSelector";
import LightningIcon from "../../../components/assets/icon/LightningIcon";
import {InformationCircleIconOrangeOnHover} from "../../../components/assets/icon/InformationCircleIcon";
import PVGISDisclaimerTooltip from "../../tooltip/PVGISDisclaimerTooltip";
import {
    selectAddress,
    selectPointBeingEdited
} from "../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {typologyFormActions} from "../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {
    selectNextStep
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/steps/selectSteps";
import {
    selectPostcode
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";
import {
    selectClosingForm
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-action/selectFormAction";
import {
    selectNewCurve
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";
import {
    atLeast1ActiveInjectionPointsWithAddress,
    selectInjectionPoints
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";
import PerimeterMap from "../consumption-point-form/PerimeterMap";

const DEFAULT_LOSS : string = "20"

export default function InjectionPointsForm() {
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar()
    const nextStep = useSelector(selectNextStep)
    const injectionPoints = useSelector(selectInjectionPoints)
    const postcode = useSelector(selectPostcode)
    const isLoadingForm = useSelector(selectIsLoadingSimulationForm)
    const isLoadingCurve = useSelector(selectIsLoadingNewCurve)
    const closingForm = useSelector(selectClosingForm)
    const rowToEdit = useSelector(selectPointBeingEdited)
    const [addInjectionBtn, setAddInjectionBtn] = useState(ButtonState.DISABLED)
    const [clear, setClear] = useState(false)
    const [pvModel, setPvModel] = useState(false)
    const [pvModelEnable, setPvModelEnable] = useState(false)
    // inputs fields / selectors
    const [name, setName] = useState("")
    const [maximumProductionPower, setMaximumProductionPower] = useState("")
    const [displayMaximumProductionPowerWarning, setDisplayMaximumProductionPowerWarning] = useState(false)
    const [displayMaximumPowerCoherenceWarning, setDisplayMaximumPowerCoherenceWarning] = useState(false)
    const [generatedKwhPrice, setGeneratedKwhPrice] = useState("")
    const [segment, setSegment] = useState(Segment.C4)
    const [productionType, setProductionType] = useState(ProductionType.photovoltaics)
    const [orientation, setOrientation] = useState("")
    const [inclinaison, setInclinaison] = useState("")
    const [loss, setLoss] = useState(DEFAULT_LOSS)
    const [showTooltip, setShowTooltip] = useState(false)
    // validations
    const isNameValidSelector = useCallback(makeIsInjectionPointNameValid(name, rowToEdit), [name, rowToEdit])
    const isNameValid = useSelector(isNameValidSelector);

    const isMaximumProductionPowerValidSelector = useCallback(makeIsMaximumProductionPowerValid(maximumProductionPower), [maximumProductionPower])
    const isMaximumProductionPowerValid = useSelector(isMaximumProductionPowerValidSelector);

    const isKwhPriceValidSelector = useCallback(makeIsPriceValid(generatedKwhPrice), [generatedKwhPrice])
    const isKwhPriceValid = useSelector(isKwhPriceValidSelector)

    const [inclinaisonIsValid, setInclinaisonIsValid] = useState(true)
    const [orientationIsValid, setOrientationIsValid] = useState(true)
    const [lossIsValid, setLossIsValid] = useState(true)

    const [maximumProductionPowerIsValid, setMaximumProductionPowerIsValid] = useState(true)
    const [generatedKwhPriceIsValid, setGeneratedKwhPriceIsValid] = useState(true)
    const [loadCurveIsValid, setLoadCurveIsValid] = useState(false)
    const [isVATRecoverable, setIsVATRecoverable] = useState(false)
    const newCurve = useSelector(selectNewCurve)
    const [displayMap, setDisplayMap] = useState(false)

    const address = useSelector(selectAddress)
    const [addressIsValid, setAddressIsValid] = useState(true)
    const selectAddressGouv = useCallback(makeSelectAddressGouvFrom(address), [address])
    const addressGouvSelected: any = useSelector(selectAddressGouv)
    const atLeast1InjectionPointWithAddressActive = useSelector(atLeast1ActiveInjectionPointsWithAddress)


    // inputs onChange
    function nameOnChange(e: string) {
        // In HTML, selector option cannot contains double spaces
        e = e.replace("  ", " ")
        setName(e);
    }

    function maximumProductionPowerOnChange(e: string) {
        if (maximumProductionPower !== "" && newCurve) {
            setDisplayMaximumProductionPowerWarning(true)
        } else {
            setDisplayMaximumProductionPowerWarning(false)
        }
        setMaximumProductionPower(e);
    }

    function generatedKwhPriceOnChange(e: string) {
        setGeneratedKwhPrice(e);
    }

    function segmentOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
        switch (e.target.value) {
            case Segment.C5 :
                setSegment(Segment.C5);
                break;
            case Segment.C4:
                setSegment(Segment.C4);
                break;
            case Segment.HTA :
                setSegment(Segment.HTA);
                break;
            default :
                break;
        }
    }

    function productionTypeOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
        switch (e.target.value) {
            case ProductionType['photovoltaics'] :
                setProductionType(ProductionType['photovoltaics']);
                break;
            case ProductionType['co-generation'] :
                setProductionType(ProductionType['co-generation']);
                break;
            case ProductionType['geothermal'] :
                setProductionType(ProductionType['geothermal']);
                break;
            case ProductionType['hydroelectric'] :
                setProductionType(ProductionType['hydroelectric']);
                break;
            case ProductionType['wind-turbine'] :
                setProductionType(ProductionType['wind-turbine']);
                break;
            default :
                break;
        }
    }

    function inclinaisonOnChange(e: string) {
        // Basic Validation
        setInclinaison(e);
    }

    function lossOnChange(e: string) {
        // Basic Validation
        setLoss(e);
    }

    function orientationOnChange(e: string) {
        // Basic Validation
        setOrientation(e);
    }


    // inputs validation
    function validateInclinaison() {
        if(inclinaison && pvModelEnable) {
            setInclinaisonIsValid(!isNaN(Number(inclinaison)) && Number(inclinaison) >= 0 && Number(inclinaison) <= 90)
        } else setInclinaisonIsValid(true)
    }
    useEffect(()=>{
        validateInclinaison()
    },[inclinaison, pvModelEnable])

    function validateOrientation(){
        if(orientation && pvModelEnable){
            setOrientationIsValid(!isNaN(Number(orientation)) && Number(orientation) >= -180 && Number(orientation) <= 180)
        } else setOrientationIsValid(true)
    }
    useEffect(()=>{
        validateOrientation()
    },[orientation, pvModelEnable])

    function validateLoss(){
        if(loss && pvModelEnable){
            setLossIsValid(!isNaN(Number(loss)) && Number(loss) >= 0 && Number(loss) <= 100)
        } else {
            setLossIsValid(true)
        }
    }
    useEffect(()=>{
        validateLoss()
    },[loss, pvModelEnable])

    function validateGeneratedKwhPrice(){
        setGeneratedKwhPriceIsValid(!isNaN(Number(generatedKwhPrice)) && generatedKwhPrice.toString().length > 0)
    }
    useEffect(() => {
        if (generatedKwhPrice) {
            validateGeneratedKwhPrice()
        }
    }, [generatedKwhPrice])
    function validateMaximumProductionPower(){
        setMaximumProductionPowerIsValid(!isNaN(Number(maximumProductionPower)) && maximumProductionPower.length > 0)
    }
    useEffect(() => {
        if (maximumProductionPower) {
            validateMaximumProductionPower()
        }
    }, [maximumProductionPower])

    useEffect(() => {
        // return valid or not state of the Step1Form
        if (enableNewInjectionPointBtn()) {
            setAddInjectionBtn(ButtonState.REGULAR)
        } else {
            setAddInjectionBtn(ButtonState.DISABLED)
        }
    }, [isNameValid, generatedKwhPriceIsValid, generatedKwhPrice, name, maximumProductionPower, maximumProductionPowerIsValid, loadCurveIsValid, isLoadingCurve, addressIsValid, address])

    useEffect(() => {
        // onClick Edit a row -> fill the form with the row data
        // -> Change form state in order to make user know he's editing the row
        if (rowToEdit) {
            setName(rowToEdit.name)
            setIsVATRecoverable(rowToEdit.isVATRecoverable)
            setGeneratedKwhPrice(rowToEdit.generatedKwhPrice)
            setMaximumProductionPower(rowToEdit.maximumProductionPower.toString())
            setProductionType(rowToEdit.productionType)
            setSegment(rowToEdit.segment)
            dispatch(typologyFormActions.setAddress(rowToEdit.address?.label || ""))
            dispatch(newSimulationActions.loadNewCurve(rowToEdit.loadCurveId))
            if (rowToEdit.productionType === ProductionType.photovoltaics) {
                if (rowToEdit.photovoltaicModel) {
                    if (postcode && postcode.length === 5) {
                        setPvModel(true)
                    } else {
                        enqueueSnackbar("Pour modéliser une courbe de charge avec PVGIS, il faut remplir le code postale dans l'étape 1 (Information Générale)", {variant: "warning"})
                    }
                    setInclinaison(rowToEdit.photovoltaicModel.inclinaison)
                    setOrientation(rowToEdit.photovoltaicModel.orientation)
                    setLoss(rowToEdit.photovoltaicModel.loss)
                } else {
                    setPvModel(false)
                    setInclinaison("")
                    setLoss(DEFAULT_LOSS)
                    setOrientation("")
                }
            }

        }

    }, [rowToEdit])

    useEffect(() => {
        // clear form when new point is pushed
        if (clear) {
            setName("")
            setMaximumProductionPower("")
            setGeneratedKwhPrice("")
            setInclinaison("")
            setOrientation("")
            setLoss(DEFAULT_LOSS)
            setIsVATRecoverable(false)
            setPvModel(false)
            setClear(false)
            dispatch(typologyFormActions.setEditPoint(null))
            dispatch(typologyFormActions.setAddress(""))
            dispatch(newSimulationActions.setNewCurve(null))
            dispatch(newSimulationActions.setFirstNewCurve(null))
            setDisplayMaximumProductionPowerWarning(false)
            setDisplayMaximumPowerCoherenceWarning(false)
        }
    }, [clear])

    useEffect(() => {
        if (productionType === ProductionType.photovoltaics) {
            setPvModelEnable(true)
        } else {
            setPvModelEnable(false)
        }
    }, [productionType])

    function impossibleToAddPoint(){
        validateGeneratedKwhPrice()
        validateMaximumProductionPower()
        enqueueSnackbar("Le formulaire doit être valide pour ajouter un nouveau point d'injection", {variant: "warning"})
    }
    function handleCancelEdition() {
        dispatch(newSimulationActions.setNewCurve(null))
        dispatch(newSimulationActions.setFirstNewCurve(null))
        dispatch(typologyFormActions.setEditPoint(null))
    }

    function enableNewInjectionPointBtn() {
        return isNameValid && isKwhPriceValid && isMaximumProductionPowerValid && loadCurveIsValid && !isLoadingCurve
    }

    function handleDisplayMapClick() {
        if (atLeast1InjectionPointWithAddressActive) {
            setDisplayMap(!displayMap)
        } else {
            enqueueSnackbar("Vous devez avoir au moins 1 point d'injection activé ayant son adresse renseignée.", {variant: "warning"})
        }
    }

    function createOrUpdateInjectionPoint() {
        if (newCurve && newCurve.id) {
            let newPoint: InjectionPoint = new InjectionPoint(rowToEdit?.id || uuidv4(),
                name.trim().replace("  ", " "),
                productionType,
                segment,
                generatedKwhPrice,
                parseFloat(maximumProductionPower),
                rowToEdit?.opexEntries || null,
                rowToEdit?.capexEntries,
                newCurve.id,
                pvModel ? new PhotovoltaicModel(inclinaison, orientation, loss) : null,
                rowToEdit?.state,
                isVATRecoverable,
                addressGouvSelected)
            dispatch(newSimulationActions[rowToEdit ? 'updateInjectionPoint' : 'addInjectionPoint'](newPoint))

        } else {
            enqueueSnackbar("problème de récupération de l'id de la courbe de charge",
                {
                    variant: "error",
                })
        }
        setClear(true)
        dispatch(push("#top"))
    }

    useEffect(() => {
        dispatch(newSimulationActions.setFormIsValid(injectionPoints.some((injectionPoint) => injectionPoint.state)))
    }, [injectionPoints])


    useEffect(() => {
        // asked to close this form
        if (closingForm) {
            dispatch(newSimulationActions.setCurrentStepIndex(nextStep.index))
            dispatch(newSimulationActions.setClosingForm(false))
        }
    }, [closingForm])

    function handleClickPvModel() {
        if (postcode && postcode.length === 5) {
            setPvModel(!pvModel)
        } else {
            enqueueSnackbar("Pour modéliser une courbe de charge avec PVGIS, renseignez un code postale valide dans l'étape 1 (Information Générale)", {variant: "warning"})
        }
    }

    return <div data-cy={"new-simulation-step-2"}
                className="w-full h-full flex flex-col bg-gray-100 font-sans overflow-auto dark:bg-zinc-700">
        <div className="transform-none  2xl:transform-none mx-4 2xl:mx-8 scale-95 2xl:scale-100 ">
            <span id="top"/>
            <div className={"flex w-full justify-between items-center"}>
                <div>
                    <TitleText title={"Typologie - Points d'injection"}/>
                    <DescriptionText
                        description={"Ajoutez des moyens de production à votre opération. Pour une installation photovoltaïque, il est possible de modéliser une courbe de charge."}/>
                </div>
            </div>
            <div className="flex flex-col ">
                <InjectionPointsTable
                    clearForm={() => setClear(true)}/>
                <PerimeterMap/>

                <form className="w-full 2xl:max-w-[920px]">
                    <TitleText
                        title={rowToEdit ? "Modifier le point d'injection : [" + rowToEdit.name + "]" : "Créer un nouveau point d'injection"}/>
                    <DescriptionText description={"Renseignez les informations de votre point d'injection."}/>
                    <div
                        className={rowToEdit ? "flex flex-wrap p-4 bg-blue-50 border-2 border-blue-300 dark:border-0  rounded-md dark:bg-slate-800" : "flex flex-wrap p-4"}>
                        <TextInput dataCy={"typology-injection-name"} mandatory={true} label={"NOM"} placeholder={"(ex : PV)"}
                                   id={"step2-name"} loading={isLoadingForm}
                                   onChange={nameOnChange} value={name} error={{
                            state: !isNameValid,
                            msg: name.length < 2 ? "le nom doit être composé d'au moins 2 caractères." : "Le nom doit être unique."
                        }}/>
                        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-4">
                            <SelectorInput dataCy={"typology-injection-type"} value={productionType} id={"select-type"}
                                           onChange={productionTypeOnChange}
                                           label={"TYPE"} loading={isLoadingForm}
                                           options={Object.values(ProductionType)}/>
                            <SelectorInput dataCy={"typology-injection-segment"} value={segment} id={"select-segment"}
                                           onChange={segmentOnChange}
                                           label={"SEGMENT"} loading={isLoadingForm}
                                           options={Object.values(Segment)}/>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 w-full">
                            <TextInput mandatory={true} label={"PUISSANCE INSTALLÉE"} placeholder={"(ex : 157)"}
                                       unit={UnitType.KILO_WATT}
                                       dataCy={"typology-injection-power"}
                                       isAFloat loading={isLoadingForm}
                                       id={"step2-pow"} onChange={maximumProductionPowerOnChange}
                                       unitComponent={LightningIcon()}
                                       value={maximumProductionPower} error={{
                                state: !isMaximumProductionPowerValid,
                                msg: "la puissance est requise."
                            }}/>
                            <TextInput mandatory={true} label={"VALORISATION DU kWh DE SURPLUS"}
                                       unit={UnitType.EURO_HT_BY_KILO_WATT}
                                       isAFloat loading={isLoadingForm}
                                       dataCy={"typology-injection-valorisation"}
                                       placeholder={"(ex : 0.0015)"} id={"step2-valo"} onChange={generatedKwhPriceOnChange}
                                       value={generatedKwhPrice}
                                       error={{state: !isKwhPriceValid, msg: "la valorisation est requise."}}/>
                        </div>
                        {displayMaximumProductionPowerWarning &&
                        <Notification
                            onClose={() => setDisplayMaximumProductionPowerWarning(false)}
                            state={State.WARNING}
                            text={"Vous êtes en train de modifier la puissance installée. Pensez à mettre à jour la courbe de charge associée."}
                        />
                        }
                        {displayMaximumPowerCoherenceWarning &&
                        <Notification
                            onClose={() => setDisplayMaximumPowerCoherenceWarning(false)}
                            state={State.WARNING}
                            text={"une incohérence est détectée : la puissance max de la courbe de charge est supérieure à la puissance déclarée du point d’injection"}
                        />
                        }
                        <TypologyPointAddressInput addressIsValid={addressIsValid}
                                                   setAddressIsValid={setAddressIsValid}/>
                        <span className={"my-2 w-full"}/>
                            <ChooseTVA isVATRecoverable={isVATRecoverable} setIsVATRecoverable={setIsVATRecoverable}/>

                        <hr className="my-3 w-full  dark:border-zinc-600"/>
                        {pvModelEnable &&
                        <div className="w-full my-2 flex flex-col items-center">
                            <div className="w-full flex flex-row items-center py-3">

                                <label
                                    className="mr-2 block tracking-wide text-gray-600 font-semibold dark:text-gray-300 "
                                    htmlFor="grid-last-name"> Modéliser ma production solaire</label>
                                <div className={"relative hover:cursor-pointer mr-2"}
                                     onMouseLeave={() => setShowTooltip(false)}
                                     onMouseOver={() => setShowTooltip(true)}>
                                    {InformationCircleIconOrangeOnHover()}
                                    {showTooltip && PVGISDisclaimerTooltip()}
                                </div>
                                <div data-cy={"typology-injection-pvModel"} onClick={() => handleClickPvModel()}
                                     className={pvModel ? "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-orange-200 focus:outline-none cursor-pointer shadow-sm" : "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"}
                                     role="switch" id="flexSwitchCheckDefault">
                                    <div
                                        className={pvModel ? "w-5 h-5 ml-4 bg-secondary rounded-full " : "w-5 h-5 bg-white rounded-full "}/>
                                </div>
                            </div>
                            {
                                pvModel && <div className="  grid grid-cols-1 lg:grid-cols-4 mt-2 gap-4 w-full">
                                    <div className={"relative"}>
                                        <TextInput dataCy={"typology-injection-inclinaison"} mandatory={true}
                                                   label={"INCLINAISON (°)"} id={"inclinaison"}
                                                   isAFloat loading={isLoadingForm}
                                                   onChange={inclinaisonOnChange} value={inclinaison}
                                                   placeholder={"(0 ; 90)"}
                                                   error={{
                                                       state: !inclinaisonIsValid,
                                                       msg: "L'inclinaison doit être comprise entre 0 et 90°."
                                                   }}
                                                   tooltipComponent={PVGISInclinaisonTooltip()}/>
                                    </div>
                                    <div className={"relative"}>

                                        <TextInput dataCy={"typology-injection-orientation"} mandatory={true}
                                                   label={"ORIENTATION (°)"} id={"orientation"}
                                                   onChange={orientationOnChange} value={orientation}
                                                   isAFloat loading={isLoadingForm}
                                                   placeholder={"(-180 ; 180)"}
                                                   error={{
                                                       state: !orientationIsValid,
                                                       msg: "L'orientation doit-être comprise entre -180 et 180°."
                                                   }}
                                                   tooltipComponent={PVGISOrientationTooltip()}/>
                                    </div>
                                    <TextInput mandatory={true} label={"PERTES DU SYSTÈME(%)"} id={"loss"}
                                               isAFloat
                                               onChange={lossOnChange} value={loss}
                                               error={{
                                                   state: !lossIsValid,
                                                   msg: "La perte du système doit être comprise entre 0 et 100%."
                                               }}

                                               placeholder={"(0 - 100)"}/>
                                    <TextInput id={"maximumProductionPowerForPv"}
                                               onChange={() => console.log("maximumProductionPowerForPv changed")}
                                               disabled={true} label={"PUISSANCE PV CRÊTE (KW)"}
                                               isAFloat loading={isLoadingForm}
                                               value={maximumProductionPower} placeholder={"157"}/>
                                </div>
                            }
                        </div>}

                        <LoadCurveInput chartColor="rgb(243,182,23)" orientation={orientation} angle={inclinaison}
                                        loss={loss} peakPower={maximumProductionPower} rowToEdit={rowToEdit}
                                        clear={clear}
                                        loadingState={pvModel ?
                                            (orientation.length !== 0 && inclinaison.length !== 0 && loss.length !== 0 && maximumProductionPower.length !== 0 && orientationIsValid && inclinaisonIsValid && lossIsValid && !isLoadingForm ? ButtonState.REGULAR : ButtonState.DISABLED)
                                            : ButtonState.OUTLINED}
                                        withModel={pvModel} model={pvModel ? "PVGIS" : null}
                                        setLoadCurveIsValid={setLoadCurveIsValid}
                                        maximumProductionPower={parseFloat(maximumProductionPower)}
                                        setDisplayMaximumPowerCoherenceWarning={setDisplayMaximumPowerCoherenceWarning}
                        />
                        <div className={"text-gray-500 text-xs mt-4 mb-2"}>* champs obligatoires</div>

                        <hr className={"w-full py-6 dark:border-zinc-600"}/>
                        <div className={"w-full flex"}>
                            <RegularBtn dataCy={"typology-injection-add-point"}
                                        action={enableNewInjectionPointBtn() ? createOrUpdateInjectionPoint : impossibleToAddPoint} state={addInjectionBtn}
                                        title={rowToEdit ? "Mettre à jour le point d'injection" : "Ajouter le point d'injection"}/>

                            {rowToEdit && <div className={"w-full ml-2"}>
                                <RegularBtn theme={Theme.SECONDARY} action={() => handleCancelEdition()}
                                            state={ButtonState.REGULAR} title={"Annuler"}/>
                            </div>}                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
}



