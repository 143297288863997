import {useDispatch, useSelector} from "react-redux";
import {
    makeSelectSimulations,
    selectSimulationsCurrentPage
} from "../../../../../corelogic/usecases/simulations-display/simulationsDisplaySelector";
import EmptyTable from "./EmptyTable";
import {green, Sort, State, yellow} from "../../../../../config/app-config";
import {useSnackbar} from "notistack";
import {
    simulationsDisplayActions
} from "../../../../../corelogic/usecases/simulations-display/simulationsDisplayActions";
import {useCallback, useEffect, useState} from "react";
import {push} from "connected-react-router";
import {selectAreLoadingSimulationsForm} from "../../../../../corelogic/usecases/loader/loaderSelector";
import RainbowLoader from "../../components/assets/Animated/RainbowLoader";
import {Simulation} from "../../../../../corelogic/models/types/simulations-display/Simulation";
import PaginationTable from "../../components/assets/table/PaginationTable";
import {OperationType} from "../../../../../corelogic/models/types/new-simulation/form/OperationType";


import {
    selectAreCreditsRemaining,
    selectOrganisations,
    selectRemainingNumberOfSimulations, selectSelectedOrganisation,
    selectSelectedOrganisationId
} from "../../../../../corelogic/usecases/authentication/authenticationSelector";
import {Organisation} from "../../../../../corelogic/models/types/simulations-display/Organisation";
import ConfirmModal from "../confirmation-dialog/ConfirmModal";
import TableRowBadge from "../../components/assets/table/TableRowBadge";
import ChevronUp from "../../components/assets/icon/ChevronUp";
import ChevronDown from "../../components/assets/icon/ChevronDown";

export default function () {
    const dispatch = useDispatch()
    const selectedOrganisation = useSelector(selectSelectedOrganisation)
    const isLoading = useSelector(selectAreLoadingSimulationsForm)
    const organisationId = useSelector(selectSelectedOrganisationId)
    const selectSimulations = useCallback(makeSelectSimulations(organisationId), [organisationId])
    const simulations = useSelector(selectSimulations)
    const [sortedSimulations, setSortedSimulations] = useState(simulations)
    const organisations = useSelector(selectOrganisations)
    const remainingNumberOfSimulations = useSelector(selectRemainingNumberOfSimulations)
    const creditLeft = useSelector(selectAreCreditsRemaining)
    const simulationsCurrentPage = useSelector(selectSimulationsCurrentPage)
    const [duplicationIsDone, setDuplicationIsDone] = useState<boolean>(false)
    const [sortTable, setSortTable] = useState<{ type: string, sortState: Sort }>({type: "", sortState: Sort.DISABLED})
    const [openDuplicateConfirmDialog, setOpenDuplicateConfirmDialog] = useState<any>({state: false, rowId: ""})
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        setSortedSimulations(simulations)
    }, [simulations])

    function pushToPage({action, simulationId, status}: { action: string, simulationId?: string, status?: State }) {

        if (action === 'gotoResult') {
            if (status === State.SUCCESS && simulationId) {
                dispatch(push(`/simulation/${simulationId}/physical-result`))
            } else {
                enqueueSnackbar("Terminer la simulation pour avoir accès aux pages de restitution.", {variant: 'warning'})
            }
        } else if (action === 'gotoForm' && simulationId) {
            dispatch(push(`/simulation/${simulationId}/edit`))
        } else {
            if (status === State.SUCCESS && simulationId) {
                dispatch(push(`/simulation/${simulationId}/physical-result`))
            } else {
                dispatch(push(`/simulation/${simulationId}/edit`))
            }
        }
    }

    useEffect(() => {
        if (duplicationIsDone && !isLoading) {
            setDuplicationIsDone(false)
            setOpenDuplicateConfirmDialog({state: false, rowId: ""})
        }
    }, [duplicationIsDone, isLoading])

    function duplicateSimulation() {
        const simulationToDuplicateOrganisationId: string = simulations.find((simulation) => simulation.id === openDuplicateConfirmDialog.rowId)?.organisation.id || ""
        if (organisations.some((orga: Organisation) => orga.id === simulationToDuplicateOrganisationId && orga.remaining_number_of_simulations > 0)) {
            dispatch(simulationsDisplayActions.duplicateSimulation(openDuplicateConfirmDialog.rowId))
            setDuplicationIsDone(true)
        } else {
            enqueueSnackbar(`Vous n'avez plus de crédits pour cette organisation. Contactez Enogrid pour en savoir plus.`, {variant: 'warning'})
        }
    }


    function sortDate(a: string, b: string) {
        var aa = a.split('/').reverse().join(),
            bb = b.split('/').reverse().join();
        return aa < bb ? -1 : (aa > bb ? 1 : 0);
    }

    function sort(typeOfSort: string) {
        let simulationsSorted: Simulation[] = []

        switch (typeOfSort) {
            case "ALPHABETICAL" :
                simulationsSorted = [...simulations.sort((a, b) => a.name.localeCompare(b.name))];
                break;
            case "ORGANISATION" :
                simulationsSorted = [...simulations.sort((a, b) => a.organisation.name.localeCompare(b.organisation.name))];
                break;
            case "CREATE_TIME" :
                simulationsSorted = [...simulations.sort((a, b) => sortDate(a.created, b.created))];
                break;
            case "LAST_ACTOR" :
                simulationsSorted = [...simulations.sort((a, b) => a.actor.localeCompare(b.actor))];
                break;
            case "LAST_UPDATE" :
                simulationsSorted = [...simulations.sort((a, b) => sortDate(a.lastModified, b.lastModified))];
                break;
            case "STATUS" :
                simulationsSorted = [...simulations.sort((a, b) => a.status.text.localeCompare(b.status.text))];
                break;
            case "OPERATION_TYPE" :
                simulationsSorted = [...simulations.sort((a, b) => {
                        const val1ToCompare = a.typeOfOperation || "-"
                        const val2ToCompare = b.typeOfOperation || "-"
                        return val1ToCompare.localeCompare(val2ToCompare)
                    }
                )];
                break;
            default:
                break;
        }

        if (sortTable.sortState === Sort.ORDER) {
            simulationsSorted.reverse()
        }
        setSortTable((prevState: { type: string, sortState: Sort }) => {
            if (prevState.sortState === Sort.DISABLED || prevState.sortState === Sort.REVERSE) {
                return {type: typeOfSort, sortState: Sort.ORDER}
            } else {
                return {type: typeOfSort, sortState: Sort.REVERSE}
            }
        })
        setSortedSimulations(simulationsSorted)
    }


    return <>
        {
            openDuplicateConfirmDialog.state &&
            <ConfirmModal
                title={"Dupliquer la simulation : " + simulations.filter((simulation) => openDuplicateConfirmDialog.rowId === simulation.id)[0].name}
                description={"Dupliquer une étude peut être utile pour repartir d'une simulation existante et vous créditera d'une unité. Cliquez sur Continuer pour dupliquer la simulation, sinon cliquez sur Annuler."}
                actionContinue={duplicateSimulation}
                actionCancel={() => setOpenDuplicateConfirmDialog({state: false, rowId: ""})}
                isLoading={isLoading}
            />
        }
        <div className=" shadow-md  my-6  ">
            <div className={"overflow-x-auto"}>
                <table className="w-full table-auto mt-3 bg-white dark:bg-zinc-700 rounded "
                       data-cy={"simulations-table"}>
                    <thead className={"dark:border-b-2 dark:dark:border-zinc-400"}>
                    <tr className="bg-gray-200 text-gray-600 text-sm leading-normal dark:bg-zinc-700 dark:text-zinc-300">
                        <th scope="col" onClick={() => sort("ALPHABETICAL")}
                            className="  py-3 px-2 text-left hover:text-gray-500 hover:cursor-pointer ">
                            <div className={"flex items-center justify-between"}>
                                Nom de la simulation
                                {sortTable.type === "ALPHABETICAL" ?
                                    (<div className={"text-slate-600"}>{sortTable.sortState === Sort.ORDER ?
                                        <ChevronDown/> :
                                        <ChevronUp/>}</div>)
                                    :
                                    <div className={"opacity-20"}><ChevronDown/>
                                    </div>}
                            </div>
                        </th>
                        <th scope="col" onClick={() => sort("CREATE_TIME")}
                            className=" py-3 px-2 text-left hover:text-gray-500 hover:cursor-pointer  ">
                            <div className={"flex items-center justify-between"}>
                                Date de création
                                {sortTable.type === "CREATE_TIME" ?
                                    (<div className={"text-slate-600"}>{sortTable.sortState === Sort.ORDER ?
                                        <ChevronDown/> :
                                        <ChevronUp/>}</div>)
                                    :
                                    <div className={"opacity-20"}><ChevronDown/>
                                    </div>}
                            </div>
                        </th>
                        <th onClick={() => sort("LAST_ACTOR")} scope="col"
                            className="py-3 px-2 text-left hover:text-gray-500 hover:cursor-pointer">
                            <div className={"flex items-center justify-between"}>
                                Dernier acteur
                                {sortTable.type === "LAST_ACTOR" ?
                                    (<div className={"text-slate-600"}>{sortTable.sortState === Sort.ORDER ?
                                        <ChevronDown/> :
                                        <ChevronUp/>}</div>)
                                    :
                                    <div className={"opacity-20"}><ChevronDown/>
                                    </div>}
                            </div>
                        </th>
                        <th onClick={() => sort("LAST_UPDATE")} scope="col"
                            className="py-3 px-2 text-left hover:text-gray-500 hover:cursor-pointer">
                            <div className={"flex items-center justify-between"}>
                                Dernière
                                modification
                                {sortTable.type === "LAST_UPDATE" ?
                                    (<div className={"text-slate-600"}>{sortTable.sortState === Sort.ORDER ?
                                        <ChevronDown/> :
                                        <ChevronUp/>}</div>)
                                    :
                                    <div className={"opacity-20"}><ChevronDown/>
                                    </div>}
                            </div>
                        </th>
                        <th data-cy={"simulations-table-sort-status"} onClick={() => sort("OPERATION_TYPE")} scope="col"
                            className="py-3 px-2 text-left hover:text-gray-500 hover:cursor-pointer">
                            <div className={"flex items-center justify-between"}>
                                Type d'opération
                                {sortTable.type === "OPERATION_TYPE" ?
                                    (<div className={"text-slate-600"}>{sortTable.sortState === Sort.ORDER ?
                                        <ChevronDown/> :
                                        <ChevronUp/>}</div>)
                                    :
                                    <div className={"opacity-20"}><ChevronDown/>
                                    </div>}
                            </div>
                        </th>

                        <th onClick={() => sort("STATUS")} scope="col"
                            className="py-3 px-2 text-left hover:text-gray-500 hover:cursor-pointer">
                            <div className={"flex items-center justify-between"}>
                                Statut
                                {sortTable.type === "STATUS" ?
                                    (<div className={"text-slate-600"}>{sortTable.sortState === Sort.ORDER ?
                                        <ChevronDown/> :
                                        <ChevronUp/>}</div>)
                                    :
                                    <div className={"opacity-20"}><ChevronDown/>
                                    </div>}
                            </div>
                        </th>
                        {
                            !organisationId && <th onClick={() => sort("ORGANISATION")} scope="col"
                                                   className="py-3 px-2 text-left hover:text-gray-500 hover:cursor-pointer">
                                <div className={"flex items-center justify-between"}>
                                    Organisation
                                    {sortTable.type === "ORGANISATION" ?
                                        (<div className={"text-slate-600"}>{sortTable.sortState === Sort.ORDER ?
                                            <ChevronDown/> :
                                            <ChevronUp/>}</div>)
                                        :
                                        <div className={"opacity-20"}><ChevronDown/>
                                        </div>}
                                </div>
                            </th>
                        }
                        <th scope="col" className="py-3 px-2 text-left ">Actions</th>
                    </tr>
                    </thead>
                    {isLoading ? <LoadingTable/>
                        :
                        <tbody className="text-gray-600 text-sm font-light ">
                        {sortedSimulations.map((row, index) => {

                                return <tr key={row.id}
                                           className={"border-b border-gray-200 dark:border-zinc-600 bg-white hover:bg-gray-100 dark:bg-zinc-700 dark:text-zinc-500 dark:hover:bg-zinc-600" + (index % 2 === 1 ? " bg-gray-50 dark:bg-zinc-700" : "")}>
                                    <td onClick={() => pushToPage({
                                        action: 'gotoFormOrResult',
                                        simulationId: row.id,
                                        status: row.status.state
                                    })}
                                        className="py-3 px-3 text-left whitespace-nowrap font-medium hover:font-semibold hover:cursor-pointer">
                                        <div className="flex items-center">
                            <div title={row.name}
                                  className="2xl:max-w-md max-w-[230px] text-gray-800 dark:text-zinc-200 font-semibold overflow-ellipsis overflow-hidden  whitespace-nowrap">{row.name}</div>
                                        </div>
                                    </td>

                                    <td className="py-3 px-2 text-left">
                                        <div className="flex items-center">
                                    <span
                                        className="text-gray-400 dark:text-zinc-500  font-semibold">{row.created}</span>
                                        </div>
                                    </td>
                                    <td className="py-3 px-2 text-left">
                                        <div className="flex items-center">
                                            <span
                                                className="text-gray-400 dark:text-zinc-500 font-semibold">{row.actor}</span>
                                        </div>
                                    </td>
                                    <td className="py-3 px-2 text-left">
                                        <div className="flex text-gray-400 dark:text-zinc-500">{row.lastModified}</div>
                                    </td>
                                    <td className="py-3 px-2 text-left">
                                        <div
                                            className="flex items-center w-min text-teal-500 dark:text-teal-300 font-bold text-xs px-3 py-1 rounded-full bg-teal-100 dark:bg-teal-900">{row.typeOfOperation ? OperationType[row.typeOfOperation] : "-"}</div>
                                    </td>
                                    <TableRowBadge text={row.status.text}
                                                   color={row.status.state === State.SUCCESS ? green : yellow}/>
                                    {
                                        !organisationId &&
                                        <td className="py-3 px-2 text-left">
                                            <div
                                                className="flex items-center overflow-hidden overflow-ellipsis w-min whitespace-nowrap text-teal-500 dark:text-teal-300 font-bold text-xs px-3 py-1 rounded-full bg-teal-100 dark:bg-teal-900">
                                                {row.organisation.name}
                                            </div>
                                        </td>
                                    }
                                    <td className="py-3 px-2 text-left">
                                <span data-cy={"simulations-table-dashboard"} title={"Visualiser"} className="flex">
                                    <div onClick={() => pushToPage({
                                        action: 'gotoResult',
                                        simulationId: row.id,
                                        status: row.status.state
                                    })} className={" icon " + " hover:cursor-pointer "}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                                        </svg>
                                    </div>
                                    <span data-cy={"simulations-table-edit"} title={"Editer"}
                                          onClick={() => pushToPage({action: 'gotoForm', simulationId: row.id})}
                                          className={" icon " + " hover:cursor-pointer "}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                                        </svg>
                                    </span>
                                    {/*<svg xmlns="http://www.w3.org/2000/svg" className={" icon " + " hover:cursor-pointer "} fill="none" viewBox="0 0 24 24"*/}
                                    {/*     stroke="currentColor" strokeWidth="2">*/}
                                    {/*    <path strokeLinecap="round" strokeLinejoin="round"*/}
                                    {/*          d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"/>*/}
                                    {/*</svg>*/}
                                    <span data-cy={"simulations-table-duplicate"} title={"Dupliquer"}
                                          onClick={() => setOpenDuplicateConfirmDialog({state: true, rowId: row.id})}
                                          className={" icon " + " hover:cursor-pointer "}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className={" icon " + " hover:cursor-pointer "} fill="none"
                                             viewBox="0 0 24 24"
                                             stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"/>
                                        </svg>
                                    </span>
                                </span>
                                    </td>
                                </tr>
                            } )
                        }
                        </tbody>
                    }
                </table>
            </div>
            {sortedSimulations.length !== 0 && <PaginationTable />}
            {
                !isLoading && sortedSimulations.length === 0 && <EmptyTable
                    title={"Pas encore de simulations"}
                    description={"Commencer à créer des simulations que vous pourrez retrouver ici."}
                    btnText={"Créer une nouvelle simulation"}
                    btnDisabled={selectedOrganisation ? remainingNumberOfSimulations === 0 : !creditLeft}/>
            }
        </div>
    </>
}


const LoadingTable = () => {

    const LoadingBar = ({index}:{index:number}) => <td className={"py-3 px-2 pr-6 text-left"}>
        <div className={(index === 3 || index === 5) ? ' bg-teal-100 bg-opacity-60 dark:bg-teal-800  h-[20px] mx-auto  rounded  w-full  ' : ' bg-gray-200 dark:bg-zinc-600  h-[20px] mx-auto  rounded  w-full '}></div>
    </td>

    const LoadingRow = () => <tr>
        <td className={"py-3 px-2 text-left"}>
            <div className={"h-[20px]  mx-auto bg-gray-300 bg-opacity-60 rounded dark:bg-zinc-500 max-w-[230px]"}></div>
        </td>

        {
            Array.from({length: 7}).map((_, index) => <LoadingBar index={index}/> )
        }
    </tr>

    return <tbody className="text-gray-600 text-sm font-light animate-pulse w-full ">
    {
        Array.from({length: 5}, () => <LoadingRow/>)
    }
    <tr className={'border-t border-gray-200 dark:border-zinc-400'}>
        <td className={"py-3 px-2 pr-6 text-left"}>
            <div className={' bg-gray-200 bg-opacity-60 dark:bg-zinc-500  w-[200px] mx-auto  rounded  h-[25px] '}></div>
        </td>
        {Array.from({length:6}, () => <td></td>)}
        <td className={"py-3 px-2 pr-6 text-right"}>
            <div className={' bg-gray-100 bg-opacity-60 dark:bg-zinc-600  w-[60px] mx-auto  rounded   h-[25px]  '}></div>
        </td>
    </tr>
    </tbody>
}