import {useDispatch, useSelector} from "react-redux";
import {ConsumptionPoint} from "../../../../../../../../corelogic/models/types/new-simulation/form/ConsumptionPoint";
import {InjectionPoint} from "../../../../../../../../corelogic/models/types/new-simulation/form/InjectionPoint";
import React, {useEffect, useState} from "react";
import ViewGridIcon from "../../../../../components/assets/icon/ViewGridIcon";
import SortDescendingIcon from "../../../../../components/assets/icon/SortDescendingIcon";
import SortAscendingIcon from "../../../../../components/assets/icon/SortAscendingIcon";
import {formActions} from "../../../../../../../../corelogic/usecases/form/formActions";
import EmptyTable from "../../../../table/EmptyTable";
import {displayKwHPrice} from "../../../../../../../../corelogic/models/types/new-simulation/form/ComplementProviderKwhPrice";
import {
    getKeyByValue,
    SellingPriceFunction
} from "../../../../../../../../corelogic/models/types/new-simulation/form/SellingPriceFunction";
import {
    selectFormDuration,
    selectSellingPriceInflationToApply
} from "../../../../../../../../corelogic/usecases/form/formSelector";
import {InflationInterval} from "../../variable-inflation-widget/VariableInflationInput";
import {
    FinancialParameters
} from "../../../../../../../../corelogic/models/types/new-simulation/form/FinancialParameters";
import {
    selectConsumptionPoints, selectFinancialParameters
} from "../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import { selectInjectionPoints } from "../../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";

type sellingPriceTableType = {
    sellingPriceTableRefresh: boolean,
    setSellingPriceTableRefresh(v: boolean): void,
    valueToAffect?: string,
    typeOfAffectation: SellingPriceFunction,
    disabled?:boolean
}
function serializeInflation(inflation :  string | InflationInterval[] | undefined, duration : string):number[]{
    if(typeof inflation === "string"){
        return Array(parseInt(duration)-1).fill(parseFloat(inflation))
    } else if(inflation) {
        return inflation.map((inflation)=>{
            const repeatNtimes = inflation.endYear - inflation.startYear
            return Array(repeatNtimes).fill(inflation.value)
        }).flat()
    } else return []
}

export default function SellingPriceTable({
                                              sellingPriceTableRefresh,
                                              setSellingPriceTableRefresh,
                                              valueToAffect,
                                              typeOfAffectation,
                                                disabled
                                          }: sellingPriceTableType) {

    const dispatch = useDispatch()
    const durationForm = useSelector(selectFormDuration)
    const injectionPoints = useSelector(selectInjectionPoints)
    const soutiragePoints = useSelector(selectConsumptionPoints)
    const storeFinancialParameters = useSelector(selectFinancialParameters)
    const sellingPriceInflationToApply = useSelector(selectSellingPriceInflationToApply)
    const [cellSelected, setCellSelected] = useState<boolean[][]>(soutiragePoints.map(() => {
        return injectionPoints.map(() => false)
    }))
    const [cellValue, setCellValue] = useState<any[][]>(soutiragePoints.map(() => {
        return injectionPoints.map(() => Object({
            type: SellingPriceFunction.FIXED_PRICE,
            value: 0
        }))
    }))

    const [styleOfCells, setStyleOfCells] = useState<string[][]>(soutiragePoints.map(() => {
        return injectionPoints.map(() => " w-full h-full  font-bold  text-right focus:outline-none px-4 py-8  bg-slate-50  whitespace-nowrap hover:cursor-cell hover:bg-slate-200 dark:bg-zinc-800 dark:hover:bg-zinc-700")
    }))

    const [disabledCells, setDisabledCells] = useState<boolean[][]>(soutiragePoints.map(() => {
        return injectionPoints.map(() => false)
    }))

    function cellClick(rowIdx: number, colIdx: number) {
        const newCellSelected = [...cellSelected]
        newCellSelected[rowIdx][colIdx] = !newCellSelected[rowIdx][colIdx]
        setCellSelected(newCellSelected)
    }

    function selectAllCells() {
        if (cellSelected.some((cellRowSelected, rowIndex) => {
            return cellRowSelected.some((cellState, colIndex) => {
                return cellState
            })
        })) {
            setCellSelected(soutiragePoints.map(() => {
                return injectionPoints.map(() => false)
            }))
        } else {
            setCellSelected(soutiragePoints.map(() => {
                return injectionPoints.map(() => true)
            }))
        }
    }

    function selectRow(rowIndex: number) {
        let newCellsSelection = [...cellSelected]
        if (!newCellsSelection[rowIndex].some((cell, colIndex: number) => !newCellsSelection[rowIndex][colIndex])) {
            newCellsSelection[rowIndex].forEach((cell, colIndex: number) => newCellsSelection[rowIndex][colIndex] = false)
        } else {
            newCellsSelection[rowIndex].forEach((cell, colIndex: number) => newCellsSelection[rowIndex][colIndex] = true)
        }
        setCellSelected(newCellsSelection)
    }

    function selectCols(colIndex: number) {
        let newCellsSelection = [...cellSelected]
        if (!newCellsSelection.some((rowCells, rowIndex) => !newCellsSelection[rowIndex][colIndex])) {
            newCellsSelection.forEach((rowCells, rowIndex) => {
                newCellsSelection[rowIndex][colIndex] = false
            })
        } else {
            newCellsSelection.forEach((rowCells, rowIndex) => {
                newCellsSelection[rowIndex][colIndex] = true
            })
        }
        setCellSelected(newCellsSelection)
    }

    useEffect(() => {
        if (sellingPriceTableRefresh) {
            let newCellsValues = [...cellValue]
            newCellsValues.forEach((cellValuesRow: number[], rowIndex: number) => {
                cellValuesRow.forEach((cellVal: number, colIndex: number) => {
                    if (cellSelected[rowIndex][colIndex] && !disabledCells[rowIndex][colIndex]) {
                        newCellsValues[rowIndex][colIndex] = {
                            type: typeOfAffectation,
                            value: valueToAffect,
                            inflation: serializeInflation(sellingPriceInflationToApply, durationForm)
                        }
                    }
                })
            })
            setCellValue(newCellsValues)
            setSellingPriceTableRefresh(false)
            setCellSelected(soutiragePoints.map(() => {
                return injectionPoints.map(() => false)
            }))
        }
    }, [sellingPriceTableRefresh])


    useEffect(() => {
        let newDisabledCells: boolean[][] = [...disabledCells]
        if (soutiragePoints) {
            soutiragePoints.forEach((sout: ConsumptionPoint, rowIndex) => {
                if (sout.injectionPointId && injectionPoints) {
                    injectionPoints.map((inj, colIndex) => {
                        if (inj.id === sout.injectionPointId) {
                            newDisabledCells[rowIndex][colIndex] = true
                        }
                    })
                }

            })
            setDisabledCells(newDisabledCells)
        }

    }, [injectionPoints, soutiragePoints])
    useEffect(() => {
        let newCellsStyle: string[][] = [...styleOfCells]

        cellValue.forEach((cellValuesRow: number[], rowIndex: number) => {
            cellValuesRow.forEach((cellVal: number, colIndex: number) => {
                if (disabledCells[rowIndex][colIndex]) {
                    newCellsStyle[rowIndex][colIndex] = "w-full h-full font-semibold   text-right focus:outline-none border-box px-4 py-6  bg-slate-300  whitespace-nowrap   dark:bg-zinc-700  "
                } else {
                    if (cellSelected[rowIndex][colIndex]) {
                        newCellsStyle[rowIndex][colIndex] = ' w-full h-16 flex-col flex items-center   justify-center  text-right font-semibold  border-box whitespace-nowrap border-2 border-blue-500 hover:cursor-cell  bg-blue-100 focus:outline-none dark:border-blue-800 dark:bg-zinc-800 dark:hover:bg-zinc-700 dark:text-blue-300'
                    } else {
                        newCellsStyle[rowIndex][colIndex] = " w-full h-16 flex-col flex items-center justify-center font-semibold   text-right focus:outline-none border-box  bg-slate-50  whitespace-nowrap hover:cursor-cell hover:bg-slate-200 dark:bg-zinc-600 dark:bg-zinc-700 dark:hover:bg-zinc-500 dark:text-gray-200"
                    }
                }

            })
        })
        setStyleOfCells(newCellsStyle)
        dispatch(formActions.setSellingPriceSelected(cellSelected))
    }, [cellValue, cellSelected])

    useEffect(() => {

        if (injectionPoints && soutiragePoints) {
            const sellingPrice: any = {}
            soutiragePoints.forEach((sout: ConsumptionPoint, rowIndex) => {
                const soutirage = {[sout.id]: {}}
                Object.assign(sellingPrice, soutirage)
                injectionPoints.map((inj, colIndex) => {
                    const newCellValue = cellValue[rowIndex][colIndex] ? {
                        type: getKeyByValue(SellingPriceFunction, cellValue[rowIndex][colIndex].type),
                        value: Number(cellValue[rowIndex][colIndex].value),
                        inflation: cellValue[rowIndex][colIndex]?.inflation
                    } : {type: getKeyByValue(SellingPriceFunction, SellingPriceFunction.FIXED_PRICE), value: 0}
                    const injection = {[inj.id]: newCellValue}
                    Object.assign(sellingPrice[sout.id], injection)
                })
            })

            dispatch(formActions.setSellingPriceValue(sellingPrice))
        }
    }, [cellValue, injectionPoints, soutiragePoints])

    useEffect(() => {
        if (storeFinancialParameters && storeFinancialParameters.selling_price) {
            const newCellsValuesFromStore = [...cellValue]
            newCellsValuesFromStore.forEach((cellValuesRow: number[], rowIndex: number) => {
                cellValuesRow.forEach((cellVal: number, colIndex: number) => {
                    if(storeFinancialParameters.selling_price?.[soutiragePoints[rowIndex].id]?.[injectionPoints[colIndex].id]) {
                        newCellsValuesFromStore[rowIndex][colIndex] = storeFinancialParameters.selling_price[soutiragePoints[rowIndex].id][injectionPoints[colIndex].id]
                    }
                })
            })
            setCellValue(newCellsValuesFromStore)
        }
    }, [storeFinancialParameters?.selling_price])

    function handleCellClassName(rowIndex: number, columnIndex: number): string {
        if (disabledCells[rowIndex][columnIndex]) {
            return ""
        } else if (cellValue[rowIndex][columnIndex] && cellValue[rowIndex][columnIndex].type === SellingPriceFunction.DISCOUNT_COMPLEMENT_PRICE) {
            return "max-w-[230px] self-center whitespace-nowrap overflow-hidden overflow-ellipsis  badge bg-green-200 text-green-600 dark:bg-green-900 dark:text-green-500"
        } else if (cellValue[rowIndex][columnIndex] && cellValue[rowIndex][columnIndex].type === SellingPriceFunction.GIFTED) {
            return "max-w-[230px] self-center whitespace-nowrap overflow-hidden overflow-ellipsis  badge bg-purple-200 text-purple-600 dark:bg-purple-900 dark:text-purple-500"
        } else return " badge bg-blue-200 text-blue-500 dark:bg-blue-900 dark:text-blue-500"
    }


    function handleCellValue(rowIndex: number, columnIndex: number, long: boolean) {

        if (disabledCells[rowIndex][columnIndex]) {
            return "ACI"
        } else if (cellValue[rowIndex] && cellValue[rowIndex][columnIndex]) {
            if (cellValue[rowIndex][columnIndex].type === SellingPriceFunction.DISCOUNT_COMPLEMENT_PRICE) {
                return displayKwHPrice(soutiragePoints[rowIndex], true, cellValue[rowIndex][columnIndex].value) + "€ HT / kWh"
            } else if(cellValue[rowIndex][columnIndex].type === SellingPriceFunction.GIFTED){
                return "Partage gratuit d’électricité (nouveau)"
            } else {
                return cellValue[rowIndex][columnIndex].value + " € HT / kWh"
            }
        } else {
            return 'pas de prix'
        }
    }

    return <div
        className={"overflow-x-auto "}>
        <table className="bg-white shadow-md dark:bg-zinc-700 w-full  table-auto rounded-lg ">
            <thead>
            <tr className="bg-slate-100   text-gray-500 text-sm leading-normal dark:text-zinc-300 dark:hover:bg-zinc-800  dark:bg-zinc-800 ">
                {injectionPoints.length > 0 && soutiragePoints.length > 0 &&
                <th align={"left"} onClick={disabled ? () => {} :selectAllCells} title={"Sélection de toute les cellules"}
                    data-cy={"financial-params-selling-price-select-all"}
                    className="sticky z-50 left-0 py-5 px-4 bg-slate-100 dark:bg-zinc-800 h-full hover:cursor-pointer hover:bg-slate-200 dark:hover:bg-zinc-700">
                    <ViewGridIcon
                        className={" text-gray-700 dark:text-gray-50  dark:hover:text-gray-200  hover:text-gray-700 hover:cursor-pointer "}/>
                </th>}
                {injectionPoints.map((injectionPoint: InjectionPoint, colIndex) => {
                    return <th align={"center"} onClick={disabled ? () => {} :() => selectCols(colIndex)}
                               className={injectionPoint.state ? "py-2 z-20  px-2 hover:bg-slate-200 dark:hover:bg-zinc-700 hover:cursor-pointer " : "py-2 z-20  px-2 hover:bg-slate-200 dark:hover:bg-zinc-700 hover:cursor-pointer opacity-40"}>
                        <div className={"flex justify-between items-start"}>
                            <div className={"flex flex-col pr-2 items-start"}>
                                <div title={injectionPoint.name}
                                      className="font-bold max-w-[180px] text-gray-800 whitespace-nowrap overflow-ellipsis overflow-hidden pl-2 dark:text-gray-300">{injectionPoint.name}</div>
                                <span
                                    className={"badge font-semibold bg-slate-200 text-slate-600 dark:bg-slate-900 dark:text-slate-400 my-1"}
                                    title={"Valorisation du surplus : " + injectionPoint.generatedKwhPrice + "€ HT/kWh"}>{injectionPoint.generatedKwhPrice + "€ HT/KWh"}
                                    </span>
                            </div>
                            <SortDescendingIcon className={" text-gray-700 dark:text-gray-300"}/>
                        </div>
                    </th>
                })}
            </tr>
            </thead>
            <tbody
                className="text-gray-500 text-xs font-light dark:text-zinc-400 ">
            {soutiragePoints.map((soutiragePoint: ConsumptionPoint, rowIndex: number) => {
                return <tr
                    className={soutiragePoint.state ?
                        "border-b  border-gray-200 dark:border-zinc-700"
                        : "border-b  border-gray-200 dark:border-zinc-700 opacity-40"}>
                    <td align={"left"} onClick={disabled ? () => {} : () => selectRow(rowIndex)}
                        className={" sticky left-0 bg-slate-100   text-sm py-1 px-2  whitespace-nowrap hover:bg-slate-200 hover:cursor-pointer dark:hover:bg-zinc-700  dark:bg-zinc-800 "}>
                        <div className={"flex justify-between items-center"}>
                            <div className={"flex flex-col "}>
                                <div title={soutiragePoint.name}
                                      className=" max-w-[180px] pl-2 whitespace-nowrap overflow-ellipsis overflow-hidden font-bold text-gray-700 dark:text-gray-300">{soutiragePoint.name}</div>
                                <div className={"flex "}>
                                    <span
                                        className={"badge w-min whitespace-nowrap overflow-ellipsis overflow-hidden  font-semibold bg-zinc-200 text-zinc-600 dark:bg-zinc-600 dark:text-zinc-300 my-1"}
                                        title={"Segment : " + soutiragePoint.segment}>
                                    {soutiragePoint.segment}
                                    </span>
                                    <span
                                        className={"mx-1 badge w-min max-w-[220px] whitespace-nowrap overflow-ellipsis overflow-hidden  font-semibold bg-green-200 text-green-600 dark:bg-green-900 dark:text-green-500 my-1"}
                                        title={"Prix du kWh : " + displayKwHPrice(soutiragePoint) + "€ HT /KWh"}>
                                        {displayKwHPrice(soutiragePoint, true)}
                                    </span>

                                </div>
                            </div>
                            <SortAscendingIcon className={" rotate-90 text-gray-700 dark:text-gray-300"}/>
                        </div>
                    </td>
                    {
                        injectionPoints.map((p: InjectionPoint, columnIndex: number) => {
                            return <td align={"center"}
                                       data-cy={"financial-params-selling-price-cell-" + rowIndex + "-" + columnIndex}
                                       id={"cell-input-" + rowIndex + "-" + columnIndex}
                                       onClick={disabled ? () => {} :() => cellClick(rowIndex, columnIndex)}
                                       className={"bg-slate-50 dark:bg-zinc-700"}>
                                <div
                                    className={p.state ? styleOfCells[rowIndex][columnIndex] : styleOfCells[rowIndex][columnIndex] + " opacity-40 "}>
                                    <DiscountBadgeDisplayer cellValue={cellValue} columnIndex={columnIndex}
                                                            rowIndex={rowIndex} disabledCells={disabledCells}/>
                                    <InflationBadgeDisplayer cellValue={cellValue} columnIndex={columnIndex}
                                                             rowIndex={rowIndex} disabledCells={disabledCells}/>
                                    <div title={handleCellValue(rowIndex, columnIndex, true)}
                                          className={handleCellClassName(rowIndex, columnIndex)}>
                                    {handleCellValue(rowIndex, columnIndex, false)}
                                        </div>
                                </div>
                            </td>
                        })
                    }
                </tr>
            })
            }
            </tbody>
            {injectionPoints.length === 0 && soutiragePoints.length === 0 &&
            <EmptyTable title={"Pas de couple entre points d'injection et points de soutirage"}
                        description={"Il faut créer au moins un couple [point d'injection/point de soutirage] pour affecter les prix de vente interne."}/>}
        </table>
    </div>
}
const DiscountBadgeDisplayer = ({
                                    cellValue,
                                    rowIndex,
                                    columnIndex,
                                    disabledCells
                                }: { cellValue: any, rowIndex: number, columnIndex: number, disabledCells: any }) => {
    function handleDiscountCellValue(rowIndex: number, columnIndex: number) {
        return "Réduction : " + cellValue[rowIndex][columnIndex].value + "%"
    }

    return cellValue[rowIndex][columnIndex] &&
    cellValue[rowIndex][columnIndex].type === SellingPriceFunction.DISCOUNT_COMPLEMENT_PRICE &&
    !disabledCells[rowIndex][columnIndex] ? <div title={handleDiscountCellValue(rowIndex, columnIndex)}
                                                  className={"max-w-[200px] self-center whitespace-nowrap overflow-hidden overflow-ellipsis  badge bg-amber-200 text-amber-500 dark:bg-amber-700 dark:text-amber-300 mb-1 "}>
                                        {handleDiscountCellValue(rowIndex, columnIndex)}
                                    </div> : null
}

const InflationBadgeDisplayer = ({
                                     cellValue,
                                     rowIndex,
                                     columnIndex,
                                     disabledCells
                                 }: { cellValue: any, rowIndex: number, columnIndex: number, disabledCells: any }) => {


    function handleInflationCellValue() {
        if(!cellValue[rowIndex][columnIndex].inflation) return "inflation manquante"
        const inflations:InflationInterval[] | number = FinancialParameters.complementProviderKwhPriceInflationRateFromObject(cellValue[rowIndex][columnIndex]?.inflation)
        const isFixed = typeof inflations === "number"
        const isVariable = !isFixed

        if(isFixed) return "Inflation : " + inflations + "%"
        if(isVariable)  {
            let helperText = "Inflation variable de "
            inflations.forEach((inflation:InflationInterval, index:number) => {
                const commaOrDotText = index === inflations.length - 1 ? "." : ", "
                helperText += `${inflation.value}% sur les années ${inflation.startYear} à ${inflation.endYear} ${commaOrDotText}`
            })
            return helperText
        }

    }
    function handleBadgeDisplay(){
        if(!cellValue[rowIndex][columnIndex].inflation) return "inflation manquante"
        const inflations:InflationInterval[] | number = FinancialParameters.complementProviderKwhPriceInflationRateFromObject(cellValue[rowIndex][columnIndex]?.inflation)
        const isFixed = typeof inflations === "number"
        const isVariable = !isFixed

        if(isFixed) return inflations + " %"
        if(isVariable) return "Inflation variable ..."
    }

    return cellValue[rowIndex][columnIndex] &&
    cellValue[rowIndex][columnIndex].type === SellingPriceFunction.FIXED_PRICE_WITH_INFLATION &&
    !disabledCells[rowIndex][columnIndex] ? <div title={handleInflationCellValue()}
                                                  className={"max-w-[200px] self-center whitespace-nowrap overflow-hidden overflow-ellipsis  badge bg-red-200 text-red-500 dark:bg-red-700 dark:text-red-300 mb-1 "}>
                                        {handleBadgeDisplay()}
                                    </div> : null
}
