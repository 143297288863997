import Badge from "../../../../components/assets/tag/Badge";
import {blue} from "../../../../../../../config/app-config";
import {BoltIconOutlined} from "../../../../components/assets/icon/BoltIcon";
import React from "react";
import withConditionalRender from "../../../wrapper/withConditionalRender";

import {useSelector} from "react-redux";
import {
    selectDisplayTotalEnergyBadge,
    selectNewCurve
} from "../../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-curve/selectFormCurve";

function TotalEnergyBadge(){
    const newCurve = useSelector(selectNewCurve)
    const totalEnergyInKwh = newCurve ? (newCurve.totalEnergy/1000).toFixed(0) : 'badge-energy-error'
    return <div title={`Energie totale ${totalEnergyInKwh} kWh`}>
                <Badge enolabColor={blue} text={`${totalEnergyInKwh} kWh`}
                       Icon={() => <BoltIconOutlined className={"h-4 w-4 text-blue-800 dark:text-blue-300"}/>}/>
            </div>
}
export default withConditionalRender(TotalEnergyBadge, selectDisplayTotalEnergyBadge)
