export class Organisation {
    private readonly _id: string;
    private readonly _name: string;
    private readonly _remaining_number_of_simulations: number;
    private readonly _open_operation_allowed: boolean;


    constructor(id : string, name: string, remainingNumberOfSimulations : number, openOperationAllowed : boolean) {
        this._name = name;
        this._id = id;
        this._open_operation_allowed = openOperationAllowed
        this._remaining_number_of_simulations = remainingNumberOfSimulations

    }

    static fromObject({id, name, remainingNumberOfSimulations, openOperationAllowed}:{id:string, name: string, remainingNumberOfSimulations : number, openOperationAllowed : boolean}) {
        return new this(id, name, remainingNumberOfSimulations, openOperationAllowed)
    }


    get remaining_number_of_simulations(): number {
        return this._remaining_number_of_simulations;
    }

    get open_operation_allowed(): boolean {
        return this._open_operation_allowed;
    }

    get name(): string {
        return this._name;
    }

    get id(): string {
        return this._id;
    }
}
