import React, {useEffect, useState} from "react";
import SimulationsTable from "../composition/table/SimulationsTable";
import {useDispatch, useSelector} from "react-redux";
import {simulationsDisplayActions} from "../../../../corelogic/usecases/simulations-display/simulationsDisplayActions";
import NewPageIcon from "../components/assets/icon/NewPageIcon";
import {newSimulationActions} from "../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {
    selectAreCreditsRemaining,
    selectRemainingNumberOfSimulations,
    selectSelectedOrganisation
} from "../../../../corelogic/usecases/authentication/authenticationSelector";
import {SimulationForm} from "../../../../corelogic/models/interfaces/SimulationForm";
import {UserMailAndId} from "../../../../corelogic/models/types/new-simulation/UserMailAndId";
import {
    financialRestitutionActions
} from "../../../../corelogic/usecases/financialRestitution/financialRestitutionActions";
import {
    physicalRestitutionActions
} from "../../../../corelogic/usecases/physicalRestitution/physicalRestitutionActions";
import {push} from "connected-react-router";
import {authenticationActions} from "../../../../corelogic/usecases/authentication/authenticationActions";
import OrganisationSelector from "../components/assets/selector/OrganisationSelector";
import CreateSimulationsBtn from "../components/assets/button/CreateSimulationsBtn";
import {
    selectAllSimulations,
    selectSimulationsCurrentPage
} from "../../../../corelogic/usecases/simulations-display/simulationsDisplaySelector";
import {selectAreLoadingSimulationsForm} from "../../../../corelogic/usecases/loader/loaderSelector";
import ConfirmModal from "../composition/confirmation-dialog/ConfirmModal";

export default function () {
    const dispatch = useDispatch()
    const remainingNumberOfSimulations = useSelector(selectRemainingNumberOfSimulations)
    const creditLeft = useSelector(selectAreCreditsRemaining)
    const selectedOrganisation = useSelector(selectSelectedOrganisation)
    const allSimulations = useSelector(selectAllSimulations)
    const currentPage = useSelector(selectSimulationsCurrentPage)
    const [showConfirmNewOperation, setShowConfirmNewOperation] = useState(false)

    useEffect(() => {
        if (allSimulations.length === 0) {
            dispatch(simulationsDisplayActions.getAllSimulations())
        }
        dispatch(newSimulationActions.setCurrentStepIndex(0))
        dispatch(newSimulationActions.setNextStepIndex(0))
        dispatch(newSimulationActions.setSimulationForm(new SimulationForm("", "", "", "", new UserMailAndId("", ""), new UserMailAndId("", ""))))
        dispatch(financialRestitutionActions.initData())
        dispatch(physicalRestitutionActions.initData())
        dispatch(authenticationActions.setPasswordUpdated(false))
    }, [])

    useEffect(() => {
        dispatch(simulationsDisplayActions.getSimulations())
    }, [currentPage]);

    function redirectToCreationPage() {
        setShowConfirmNewOperation(true)
    }

    function confirmDialog() {
        dispatch(push('/simulation/create'))
    }

    const ConfirmModalDescription = () => <div>
        {"Vous êtes sur le point de démarrer une nouvelle simulation et cela peut avoir un impact sur votre facturation."}
        <ul className="list-disc space-y-2  mt-4 ml-5">
        <li className="leading-relaxed">
            Si vous lancez une nouvelle simulation depuis <strong>votre espace d'étude</strong>, cela sera décompté du nombre d'études en
            simultanée pouvant être réalisée dans le cadre de votre abonnement, <strong>sans surcoût</strong>.
        </li>
        <li className="leading-relaxed">
            Si vous lancez une nouvelle simulation depuis <strong>votre espace de sauvegarde</strong>, conformément à nos conditions
            générales de vente, vous serez facturé d'un montant de
            <span className="font-semibold text-gray-900 dark:text-white">{" 500€ HT"}</span> (facturation trimestrielle).
        </li>
        <li className="leading-relaxed">
            Si vous bénéficiez d'une formule de souscription spécifique ou hors abonnement, veuillez vous référez à vos
            conditions particulières de vente.
        </li>
    </ul>
    </div>


    return (<>
            {
                showConfirmNewOperation &&
                <ConfirmModal
                    title={"Information importante : Nouvelle simulation"}
                    description={<ConfirmModalDescription/>}
                    actionContinue={confirmDialog}
                    actionCancel={() => setShowConfirmNewOperation(false)}
                />
            }
            <div
                className={"min-w-[640px]  h-full-minus-navbar overflow-auto only:w-full flex items-start justify-center bg-gray-100 font-sans dark:bg-zinc-800"}>
                <div className="mt-8 w-5/6 scale-95 2xl:scale-100 transform-none 2xl:transform-none">
                    <div className={"flex justify-between w-full items-end"}>
                        <div className="text-2xl font-semibold text-gray-700 dark:text-gray-300 flex items-center">Les
                            simulations de
                            <OrganisationSelector/>
                        </div>
                        <div className=" flex flex-col w-min">
                            <CreateSimulationsBtn dataCy={"homepage-huge-btn"}
                                                  disabled={selectedOrganisation ? remainingNumberOfSimulations === 0 : !creditLeft}
                                                  title="Nouvelle simulation"
                                                  IconComponent={NewPageIcon} action={redirectToCreationPage}/>
                        </div>
                    </div>
                    <SimulationsTable/>
                </div>
            </div>
        </>
    )
}



