import {useSelector} from "react-redux";
import {selectAutoconsumptionRate} from "../../../../../corelogic/usecases/physicalRestitution/physicalRestitutionSelector";


export default function () {
    const autoconsumptionRate = useSelector(selectAutoconsumptionRate)
    return (<div className="relative pt-1 w-full">
        <div className="w-full flex justify-between">
            <div className="font-extrabold text-xl">{autoconsumptionRate.toFixed(1)}%</div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded  bg-[#c4c4c4] h-4">
            <div className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center  bg-[#f3b617]"
                 style={{width: `${autoconsumptionRate}%`}}></div>
        </div>
    </div>)
}