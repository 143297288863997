import {useSelector} from "react-redux";
import {Organisation} from "../../../../../../corelogic/models/types/simulations-display/Organisation";
import {selectOrganisations} from "../../../../../../corelogic/usecases/authentication/authenticationSelector";
import {selectOrganisationIdWithCreditLeft} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import React, {useEffect, useState} from "react";
import OrganisationsSelectorInput from "../../../components/assets/FormAssets/selector/OrganisationsSelectorInput";
import ConfirmModal from "../../confirmation-dialog/ConfirmModal";

export default function OrganisationInput({
                               organisationId,
                               setOrganisationId,
                               isNoSavedOrganisationId,
                                              organisationOnChange,
                                              setShowConfirmNewOperation
                           }: { organisationId: string, setOrganisationId(id: string): void, isNoSavedOrganisationId: boolean,
    organisationOnChange(id:string):void,
    setShowConfirmNewOperation(modal: { show : boolean, orgId : string | null }):void}) {
    const organisations: Organisation[] = useSelector(selectOrganisations)
    const defaultOrganisationWithLeftCredit = useSelector(selectOrganisationIdWithCreditLeft)


    useEffect(() => {
        if (isNoSavedOrganisationId && defaultOrganisationWithLeftCredit) {
            setOrganisationId(defaultOrganisationWithLeftCredit)
        }
    }, [defaultOrganisationWithLeftCredit])



    function partOfAtLeastTwoOrganisations() {
        return organisations.length > 1
    }



    function handleClickOnOtherOrganisation(id : string){
        if(isNoSavedOrganisationId || !organisationId){
            organisationOnChange(id);
            return;
        }
        if(organisationId === id) return;
        setShowConfirmNewOperation({show : true, orgId : id})
    }



    return partOfAtLeastTwoOrganisations() ?

        <div className={"mx-4 mt-2 mb-6"}>
            <div className={"w-full mb-8"}>
                <OrganisationsSelectorInput label={"ORGANISATION RATTACHÉE"} id={"step1-orga-attached"}
                                            onChange={(id:string) => handleClickOnOtherOrganisation(id)}
                                            valueId={organisationId}
                                            options={organisations}
                />
            </div>
        </div> : <></>

}

