import withRestitutionNavBar from "../composition/wrapper/withRestitutionNavBar";
import {PathnameEnum} from "../../../../corelogic/models/types/routing/PathnameEnum";
import React, {useEffect} from "react";
import {ConsumptionsRecapTable} from "../composition/table/ConsumptionsRecapTable";
import {ProductionsRecapTable} from "../composition/table/ProductionsRecapTable";
import {restitutionActions} from "../../../../corelogic/usecases/restitution/restitutionActions";
import {useDispatch, useSelector} from "react-redux";
import {

    selectSelectedSimulationId
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import {
    financialRestitutionActions
} from "../../../../corelogic/usecases/financialRestitution/financialRestitutionActions";
import {newSimulationActions} from "../../../../corelogic/usecases/new-simulation/newSimulationActions";
import TitleText from "../components/assets/text/TitleText";
import SellingPriceRecapTable from "../composition/table/SellingPriceRecapTable";
import CapexTable from "../composition/form/financial-parameters/capex/CapexTable";
import {
    selectIsOpenOperation
} from "../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectGeneralInfoForm";
import { ExecutionStatus } from "../../../../corelogic/usecases/simulationExecutionStatus/simulationExecutionStatusActions";
import { useSimulationExecutionStatus } from "../utils/useSimulationExecutionStatus";


const RecapTableResult = () => {
    const dispatch = useDispatch();
    const simulationId = useSelector(selectSelectedSimulationId)
    const isOpenOperation: boolean = useSelector(selectIsOpenOperation)
    const executionStatus = useSimulationExecutionStatus();

    useEffect(() => {
        dispatch(newSimulationActions.getSimulationForm())
    }, [dispatch])

    useEffect(() => {
        if (simulationId && executionStatus === ExecutionStatus.finished) {
            dispatch(restitutionActions.getRestitution());
            dispatch(financialRestitutionActions.getCashFlowData(undefined));
        }
    }, [dispatch, simulationId, executionStatus])

    return <div className=" min-w-[640px] w-full flex flex-col items-end bg-gray-100 font-sans overflow-y-scroll dark:bg-zinc-800">
        <div className=" bg-white shadow-sm  flex items-center w-full border-box dark:bg-zinc-800">
            <div className="flex w-full z-[50] justify-between items-center scale-95 2xl:scale-100 px-6 ">
                <TitleText title={"Tableaux de synthèse de la simulation"}/>
            </div>
        </div>
        <div className={" flex flex-col p-4 gap-6 w-full"}>
            <ConsumptionsRecapTable isOpenOperation={isOpenOperation}/>
            <ProductionsRecapTable/>
            <CapexTable disabled/>
            {isOpenOperation && <SellingPriceRecapTable/>}
        </div>
    </div>
}

export default withRestitutionNavBar(RecapTableResult, PathnameEnum.RECAP_TABLE_RESTITUTION);
