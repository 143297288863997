import {FinancialRestitutionGatewayInterface} from "../../../../corelogic/usecases/financialRestitution/financialRestitutionGateway.interface";
import {balanceMetricsNature} from "../../../../corelogic/models/types/financialRestitution/NatureEnum";

export class FinancialRestitutionInMemoryGateway implements FinancialRestitutionGatewayInterface {

    getCashFlowData(simulationId: string, nature : string): any {
        const A0 = {
            investment: -55000,
            maintenance: null,
            acSavings: null,
            excess: null,
            netSavings: 55000,
            cumulativeFlows: -55000
        };
        const A1 = {
            investment: null,
            maintenance: 0,
            acSavings: 5022,
            excess: 948,
            netSavings: 5970,
            cumulativeFlows: -49029
        }
        const A2 = {
            investment: null,
            maintenance: 1500,
            acSavings: 5207,
            excess: 948,
            netSavings: 4655,
            cumulativeFlows: -44373
        }
        const A25 = {
            investment: null,
            maintenance: 1500,
            acSavings: 5207,
            excess: 948,
            netSavings: 4655,
            cumulativeFlows: 44373
        }
        const cashFlow = [A0, A1, ...Array.from(Array(15)).map(elt => A2), ...Array.from(Array(10)).map(elt => A25)]
        return cashFlow;
    }

    getGlobalEconomicalData(simulationId: string): any {
        return {
            aci: {
                year1: 1212,
                year25: 48520,
            },
            acc: {
                year1: 3839,
                year25: 153581,
            },
            tcfe: {
                year1: 30,
                year25: 1203,
            },
            total: {
                year1: 5022,
                year25: 200898,
            },
            excess: {
                year1: 948,
                year25: 23700,
            }
        };
    }

    getByPointEconomicalData(simulationId: string): any {
        return {
            T2: {
                year1: 948,
                number: 10,
                totalYear1: 330,
                totalYear25: 13212,
            },
            SG: {
                year1: 1212,
                number: 1,
                totalYear1: 1212,
                totalYear25: 48520,
            },
            T4: {
                year1: 126,
                number: 15,
                totalYear1: 1903,
                totalYear25: 76159,
            },
            T3: {
                year1: 80,
                number: 20,
                totalYear1: 1605,
                totalYear25: 64210,
            }
        };
    }

    getSurplusByPoint(simulationId: string, pointId?: string): any{
    return {
            T2: {
                year1: 948,
                number: 10,
                totalYear1: 330,
                totalYear25: 13212,
            },
            SG: {
                year1: 1212,
                number: 1,
                totalYear1: 1212,
                totalYear25: 48520,
            },
            T4: {
                year1: 126,
                number: 15,
                totalYear1: 1903,
                totalYear25: 76159,
            },
            T3: {
                year1: 80,
                number: 20,
                totalYear1: 1605,
                totalYear25: 64210,
            }
        };
    }

    getMetricValue(simulationId: string, nature: string): number {
        switch (nature) {
            case balanceMetricsNature.totalEconomyYear1:
                return 5022;
            case balanceMetricsNature.totalEconomyYear25:
                return 200898;
            default:
                return 0.3;
        }
    }

}
