import TextInput from "../../../../components/assets/FormAssets/TextInput";
import React, {useEffect, useState} from "react";
import {isSegmentDoublePrice} from "../../../../../../../corelogic/models/types/new-simulation/form/SegmentDetailed";
import {State, UnitType} from "../../../../../../../config/app-config";
import Notification from "../../../paper/Notification";
import {useDispatch, useSelector} from "react-redux";
import {
    selectGeneratedKwhPrice,
    selectGeneratedKwhPriceIsValid,
    selectHCEtePrix,
    selectHCEtePrixIsValid,
    selectHCHiverPrix,
    selectHCHiverPrixIsValid,
    selectHCPrix,
    selectHCPrixIsValid,
    selectHeureCreuse, selectHeureCreuseIsValid,
    selectHeurePleine, selectHeurePleineIsValid,
    selectHPEteIsValid, selectHPEtePrix,
    selectHPHCIsEnabled,
    selectHPHiverPrix,
    selectHPHiverPrixIsValid,
    selectHPPrix,
    selectHPPrixIsValid,
    selectSegment
} from "../../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {typologyFormActions} from "../../../../../../../corelogic/usecases/typology-form/typologyFormActions";
import {selectIsLoadingSimulationForm} from "../../../../../../../corelogic/usecases/loader/loaderSelector";

export default function () {
    const segment = useSelector(selectSegment)
    const HPHC = useSelector(selectHPHCIsEnabled)
    return <>
        <HPHCSwitch/>
        {HPHC ? (isSegmentDoublePrice(segment) ?
                <div>
                    <HPHCHoursInput/>
                    <HPHC2QuadrantsInput/>
                </div>
                : <div>
                    <HPHCHoursInput/>
                    <HPHC4QuadrantsInput/>
                </div>)
            : <GeneratedKwhPriceInput/>}
    </>

}

function HPHCSwitch() {
    const dispatch = useDispatch()
    const HPHC = useSelector(selectHPHCIsEnabled)
    return <div className="w-full mt-2 mb-4 flex flex-row items-center">
        <label className="mr-2 block tracking-wide text-gray-600 font-semibold dark:text-zinc-300"
               htmlFor="grid-last-name"> Heures pleines / Heures creuses</label>
        <div data-cy={"typology-consumption-HPHC-switch"}
             onClick={() => dispatch(typologyFormActions.enableHPHC(!HPHC))}
             className={HPHC ? "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-orange-200 focus:outline-none cursor-pointer shadow-sm" : "form-check-input appearance-none w-9 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"}
             role="switch" id="flexSwitchCheckDefault">
            <div
                className={HPHC ? "w-5 h-5 ml-4 bg-secondary rounded-full " : "w-5 h-5 bg-white rounded-full "}/>
        </div>
    </div>
}

function HPHC2QuadrantsInput() {
    const [HPHCInvalid, setHPHCInvalid] = useState(false)

    const dispatch = useDispatch()
    const isLoadingForm = useSelector(selectIsLoadingSimulationForm)

    const HPHC = useSelector(selectHPHCIsEnabled)
    const heureCreusePrix = useSelector(selectHCPrix)
    const heurePleinePrix = useSelector(selectHPPrix)
    const heureCreusePrixIsValid = useSelector(selectHCPrixIsValid)
    const heurePleinePrixIsValid = useSelector(selectHPPrixIsValid)
    const segment = useSelector(selectSegment)

    useEffect(() => {
        if (HPHC && isSegmentDoublePrice(segment) && heureCreusePrix && heurePleinePrix) {
            if (heureCreusePrix > heurePleinePrix) {
                setHPHCInvalid(true)
            } else {
                setHPHCInvalid(false)
            }
        }

    }, [heureCreusePrix, heurePleinePrix])

    function heureCreusePrixOnChange(e: string) {
        dispatch(typologyFormActions.setHeureCreusePrix(e))
    }

    function heurePleinePrixOnChange(e: string) {
        dispatch(typologyFormActions.setHeurePleinePrix(e))
    }

    return <>
        {HPHCInvalid && <Notification state={State.WARNING}
                                      text={"Attention, le prix heure creuse est supérieure au prix heure pleine. Si ceci est intentionnel, ignorer ce message."}/>}
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-4">
            <TextInput dataCy={"typology-consumption-price-HP"} id={"prix-HP"} mandatory={true} isAFloat
                       label={"PRIX HEURES PLEINE "} value={heurePleinePrix} unit={UnitType.EURO_HT_BY_KILO_WATT}
                       onChange={heurePleinePrixOnChange} placeholder={"(ex : 0.08)"} loading={isLoadingForm}
                       error={{state: !heurePleinePrixIsValid, msg: "Le format du prix est invalide."}}/>
            <TextInput dataCy={"typology-consumption-price-HC"} id={"prix-HC"} mandatory={true} isAFloat
                       label={"PRIX HEURES CREUSE "} value={heureCreusePrix} unit={UnitType.EURO_HT_BY_KILO_WATT}
                       onChange={heureCreusePrixOnChange} placeholder={"(ex : 0.06)"} loading={isLoadingForm}
                       error={{state: !heureCreusePrixIsValid, msg: "Le format du prix est invalide."}}/>
        </div>
    </>
}

function GeneratedKwhPriceInput() {
    const dispatch = useDispatch()
    const generatedKwhPrice = useSelector(selectGeneratedKwhPrice)
    const generatedKwhPriceIsValid = useSelector(selectGeneratedKwhPriceIsValid)
    const isLoadingForm = useSelector(selectIsLoadingSimulationForm)

    function generatedKwhPriceOnChange(e: string) {
        dispatch(typologyFormActions.setGeneratedKwhPrice(e));
    }

    return <TextInput dataCy={"typology-consumption-price-kwh"}
                      id={"prix-achat"} mandatory={true}
                      label={"PRIX D'ACHAT DU kWh (HORS TURPE ET TAXES) "} isAFloat value={generatedKwhPrice}
                      unit={UnitType.EURO_HT_BY_KILO_WATT}
                      error={{state: !generatedKwhPriceIsValid, msg: "Le format du prix est invalide."}}
                      onChange={generatedKwhPriceOnChange} placeholder={"(ex : 0.11)"}/>
}

function HPHCHoursInput() {
    const dispatch = useDispatch()

    const heureCreuse = useSelector(selectHeureCreuse)
    const heurePleine = useSelector(selectHeurePleine)

    const heureCreuseIsValid = useSelector(selectHeureCreuseIsValid)
    const heurePleineIsValid = useSelector(selectHeurePleineIsValid)

    function heureCreuseOnChange(e: string) {
        dispatch(typologyFormActions.setHeureCreuse(e))
    }

    function heurePleineOnChange(e: string) {
        dispatch(typologyFormActions.setHeurePleine(e))
    }

    return <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-4">
        <TextInput id={"heure-pleine"} mandatory={true} error={{state: !heurePleineIsValid, msg: "Le format attendu est HH:MM:SS."}}
                   label={"HEURES PLEINE (début)"} value={heurePleine}
                   onChange={heurePleineOnChange} placeholder={"06:00:00"}/>
        <TextInput id={"heure-creuse"} mandatory={true} error={{state: !heureCreuseIsValid, msg:  "Le format attendu est HH:MM:SS."}}
                   label={"HEURES CREUSES (début)"} value={heureCreuse}
                   onChange={heureCreuseOnChange} placeholder={"22:00:00"}/>
    </div>
}

function HPHC4QuadrantsInput() {
    const dispatch = useDispatch()
    const [HPHCInvalid, setHPHCInvalid] = useState(false)
    const isLoadingForm = useSelector(selectIsLoadingSimulationForm)

    const segment = useSelector(selectSegment)
    const HPHC = useSelector(selectHPHCIsEnabled)
    const heureCreuseHiverPrixIsValid = useSelector(selectHCHiverPrixIsValid)
    const heurePleineHiverPrixIsValid = useSelector(selectHPHiverPrixIsValid)
    const heurePleineEtePrixIsValid = useSelector(selectHPEteIsValid)
    const heureCreuseEtePrixIsValid = useSelector(selectHCEtePrixIsValid)
    const heureCreuseEtePrix = useSelector(selectHCEtePrix)
    const heureCreuseHiverPrix = useSelector(selectHCHiverPrix)
    const heurePleineEtePrix = useSelector(selectHPEtePrix)
    const heurePleineHiverPrix = useSelector(selectHPHiverPrix)


    function heureCreuseHiverPrixOnChange(e: string) {
        dispatch(typologyFormActions.setHeureCreuseHiverPrix(e))
    }

    function heurePleineHiverPrixOnChange(e: string) {
        dispatch(typologyFormActions.setHeurePleineHiverPrix(e))
    }


    function heureCreuseEtePrixOnChange(e: string) {
        dispatch(typologyFormActions.setHeureCreuseEtePrix(e))
    }

    function heurePleineEtePrixOnChange(e: string) {
        dispatch(typologyFormActions.setHeurePleineEtePrix(e))
    }

    useEffect(() => {
        if (HPHC && !isSegmentDoublePrice(segment) && heureCreuseEtePrix && heurePleineEtePrix) {
            if (heureCreuseEtePrix > heurePleineEtePrix) {
                setHPHCInvalid(true)
            } else {
                setHPHCInvalid(false)
            }
        }

    }, [heureCreuseEtePrix, heurePleineEtePrix])

    useEffect(() => {
        if (HPHC && !isSegmentDoublePrice(segment) && heureCreuseHiverPrix && heurePleineHiverPrix) {
            if (heureCreuseHiverPrix > heurePleineHiverPrix) {
                setHPHCInvalid(true)
            } else {
                setHPHCInvalid(false)
            }
        }

    }, [heureCreuseHiverPrix, heurePleineHiverPrix])

    return <>
        {HPHCInvalid && <Notification state={State.WARNING}
                                      text={"Attention, le prix heure creuse est supérieure au prix heure pleine. Si ceci est intentionnel, ignorer ce message."}/>}
        <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-x-4">
            <TextInput dataCy={"typology-consumption-price-HP-winter"} id={"prix-HP-winter"} mandatory={true} isAFloat
                       label={"PRIX HEURES PLEINE HIVER "} value={heurePleineHiverPrix} loading={isLoadingForm}
                       unit={UnitType.EURO_HT_BY_KILO_WATT}
                       onChange={heurePleineHiverPrixOnChange} placeholder={"(ex : 0.08)"}
                       error={{state: !heurePleineHiverPrixIsValid, msg: "Le format du prix est invalide."}}/>
            <TextInput dataCy={"typology-consumption-price-HC-winter"}
                       id={"prix-HC-winter"} mandatory={true} isAFloat loading={isLoadingForm}
                       label={"PRIX HEURES CREUSE HIVER "} value={heureCreuseHiverPrix}
                       unit={UnitType.EURO_HT_BY_KILO_WATT}
                       onChange={heureCreuseHiverPrixOnChange} placeholder={"(ex : 0.06)"}
                       error={{state: !heureCreuseHiverPrixIsValid, msg: "Le format du prix est invalide."}}/>
            <TextInput dataCy={"typology-consumption-price-HP-summer"}
                       id={"prix-HP-ete"} mandatory={true} isAFloat loading={isLoadingForm}
                       label={"PRIX HEURES PLEINE ÉTÉ "} value={heurePleineEtePrix} unit={UnitType.EURO_HT_BY_KILO_WATT}
                       onChange={heurePleineEtePrixOnChange} placeholder={"(ex : 0.08)"}
                       error={{state: !heurePleineEtePrixIsValid, msg: "Le format du prix est invalide."}}/>
            <TextInput dataCy={"typology-consumption-price-HC-summer"} id={"prix-HC-ete"} mandatory={true} loading={isLoadingForm} isAFloat
                       label={"PRIX HEURES CREUSE ÉTÉ "} value={heureCreuseEtePrix} unit={UnitType.EURO_HT_BY_KILO_WATT}
                       onChange={heureCreuseEtePrixOnChange} placeholder={"(ex : 0.06)"}
                       error={{state: !heureCreuseEtePrixIsValid, msg: "Le format du prix est invalide."}}/>
        </div>
    </>
}
