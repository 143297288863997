import {Curve, Measurement, StatusEnum} from '../../../corelogic/models/types/new-simulation/curve/Curve';
import {NewSimulationGatewayInterface} from '../../../corelogic/usecases/new-simulation/newSimulationGateway.interface';
import {CurveLoaded} from '../../../corelogic/models/types/new-simulation/curve/CurveLoaded';
import {SimulationForm} from '../../../corelogic/models/interfaces/SimulationForm';
import {UserMailAndId} from '../../../corelogic/models/types/new-simulation/UserMailAndId';
import {Typology} from '../../../corelogic/models/types/new-simulation/form/Typology';
import {AddressGouv} from '../../../corelogic/models/types/new-simulation/external-api/AddressGouv';
import {GeneralInfo} from '../../../corelogic/models/types/new-simulation/form/GeneralInfo';
import {LoadCurveBuilder} from "../../primary/ui/composition/form/load-curve-upload/upload-unsaved-cdc/curve.builder";
import {SGEResults} from './APINewSimulationGateway';
import {resultE1E1E2SuccessE2SuccessE2E2E2} from "../../primary/ui/composition/form/load-curve-upload/sge-cdc/results/Results.fakeData";
import LowCompletudeInput
    from "../../primary/ui/composition/form/load-curve-upload/completude-and-recalage-button/low-completeness/form/table/LowCompletudeInput";
import {
    OperationParameters
} from "../../../corelogic/models/types/new-simulation/form/operation-parameters/OperationParameters";
import {FinancialParameters} from "../../../corelogic/models/types/new-simulation/form/FinancialParameters";
const fakeSimulationForm = new SimulationForm(
    "simu-inmemory-1", // id
    "org1", // organisationId
    "2025-02-11T10:00:00Z", // creation_timestamp
    "2025-02-11T12:00:00Z", // update_timestamp
    new UserMailAndId( "updater@example.com", "user-001" ), // created_by
    new UserMailAndId("updater@example.com", "user-002" ), // updated_by
    new GeneralInfo("Projet Alpha", "75001", "estate"), // general_info
    new Typology([],[]),
);
const memorySimulationInfos = {
    id: '62713a5686f6769eefe004b3',
    creation_timestamp: '2022-05-03T16:21:10.594000',
    update_timestamp: '2022-05-03T16:21:10.594000',
    created_by: {
        email: 'toto@mail.com',
        id: '8ae83e9a-a53f-4553-997f-0cffb24a7cf1',
    },
    updated_by: {
        email: 'toto@mail.com',
        id: '8ae83e9a-a53f-4553-997f-0cffb24a7cf1',
    },
};

export class InMemoryNewSimulationGateway implements NewSimulationGatewayInterface {
    applyLowDataCompletion(measurement: Measurement[], table: LowCompletudeInput[], targetYear: number, simulationId: string, organisationId: string): Curve | Promise<Curve> {
        const curveBuilder = new LoadCurveBuilder()
        return curveBuilder.withStatus(StatusEnum.VALID).build()
    }
    applyAnnualCompletion(measurement: Measurement[], targetYear: number, simulationId: string, organisationId: string): Curve | Promise<Curve> {
        const curveBuilder = new LoadCurveBuilder()
        return curveBuilder.withStatus(StatusEnum.VALID).build()
    }

    applyRecalage(m : Measurement[], targetYear:number, simulationId:string, organisationId:string, recalageInput: string): Curve | Promise<Curve> {
        const curveBuilder = new LoadCurveBuilder()
        const {measurement, maxPower,totalEnergy, status} = curveBuilder.applyRandomRecalage().build()
        return new Curve(null, measurement, maxPower,totalEnergy, status)
    }

    getAddressFromAPI(address: string): AddressGouv[] | Promise<AddressGouv[]> {
        throw new Error('Method not implemented.');
    }

    getSimulationFromId(simulationId: string): SimulationForm | Promise<SimulationForm> {
        return fakeSimulationForm;
    }

    postSimulation(organisationId: string): SimulationForm | Promise<SimulationForm> {
        // first step validated = post a new simulation every other step will relate to this one and be updateSimulation
        return new SimulationForm(memorySimulationInfos.id, 'id123', memorySimulationInfos.creation_timestamp, memorySimulationInfos.update_timestamp, new UserMailAndId(memorySimulationInfos.created_by.email, memorySimulationInfos.created_by.id), new UserMailAndId(memorySimulationInfos.updated_by.email, memorySimulationInfos.updated_by.id), new GeneralInfo('', '', null), new Typology([], []));
    }

    updateSimulation(newSimulation: SimulationForm): SimulationForm | Promise<SimulationForm> {
        return new SimulationForm(memorySimulationInfos.id, 'id123', memorySimulationInfos.creation_timestamp, memorySimulationInfos.update_timestamp, new UserMailAndId(memorySimulationInfos.created_by.email, memorySimulationInfos.created_by.id), new UserMailAndId(memorySimulationInfos.updated_by.email, memorySimulationInfos.updated_by.id), newSimulation._general_info, new Typology(newSimulation._typology?._injectionPoints || [], newSimulation._typology?._consumptionPoints || []), newSimulation._operation_parameters, newSimulation._financial_parameters);
    }

    loadCurveFromId(curveId: string): Promise<Curve> | Curve {
        const curveBuilder = new LoadCurveBuilder()
        const {measurement, maxPower,totalEnergy, status} = curveBuilder.build()
        return new Curve(curveId, measurement, maxPower, totalEnergy, status)
    }

    loadCurvesFromPVGIS(postCode: string, peakPower: number, loss: number, angle: number, orientation: number, organisationId: string, simulationId: string): CurveLoaded {
        const res = {
            type: 'energy',
            nature: 'production',
            step_length: 0,
            id: 'PVGIS_inmemory',
            creation_timestamp: '2022-05-04T14:10:48.447Z',
            created_by: {
                email: 'string',
                id: 'string',
            },
            source: 'user',
            based_on: ['string'],
            operation_applied: 'string',
        };
        return new CurveLoaded('201', [], res.id);
    }

    loadCurvesFromEnedis(postCode: string, accommodationType: string, heatingType: string, organisationId: string, simulationId: string): CurveLoaded {
        const res = {
            type: 'energy',
            nature: 'production',
            step_length: 0,
            id: 'ENEDIS_inmemory',
            creation_timestamp: '2022-05-04T14:19:15.673Z',
            created_by: {
                email: 'string',
                id: 'string',
            },
            source: 'user',
            based_on: ['string'],
            operation_applied: 'string',
        };
        return new CurveLoaded('201', [], res.id);
    }

    loadCurvesFromBody(type: string, nature: string, stepLength: number, body: { timestamp: string; value: number }[], organisationId: string, simulationId: string): CurveLoaded {
        const res = {
            type: 'energy',
            nature: 'production',
            step_length: 0,
            id: 'Drag&Drop_inmemory',
            creation_timestamp: '2022-05-04T14:22:35.273Z',
            created_by: {
                email: 'string',
                id: 'string',
            },
            source: 'user',
            based_on: ['string'],
            operation_applied: 'string',
        };
        return new CurveLoaded('201', [], res.id);
    }

    loadUnsavedCurveFromBody(rawBody: FormData, organisationId: string, simulationId: string): Curve | Promise<Curve> {
        const curveBuilder = new LoadCurveBuilder()
        return curveBuilder.withEndDate('05/01/2022 23:30').withStatus(StatusEnum.REJECTED).build()
    }

    saveLoadCurve(measurement: Measurement[], simulationId: string, organisationId: string): Promise<CurveLoaded> | CurveLoaded {
        return new CurveLoaded('201', [], 'new-curve-saved-in-backend');
    }

    loadUnsavedCurveFromSGE(prm: string, retries: string, delay: string, ifHolesFetch: boolean, startDate: string, endDate: string, organisationId: string, simulationId: string): { sgeResults: SGEResults; newCurve: Curve; } | Promise<{ sgeResults: SGEResults; newCurve: Curve; }> {
        const curveBuilder = new LoadCurveBuilder()
        const sgeResults: SGEResults = {
            failuresByWeeks: resultE1E1E2SuccessE2SuccessE2E2E2.failuresByWeeks,
            successfullResponseNumber: 2,
            successRate: 2/9
        }
        const curveWithSGEResults = {
            sgeResults: sgeResults,
            newCurve: curveBuilder.withStartDate(startDate).withEndDate(endDate).withStatus(StatusEnum.STANDARD_COMPLETION_COMPLIANT).build()
        }
        return curveWithSGEResults
    }

    optimizePerimeter(simulationId: string, circleDiameter:number): Promise<{ lng: number; lat: number }> | { lng: number; lat: number } {
        return {lng : 0, lat : 0};
    }
}


