import {
    SimulationsDisplayGatewayInterface
} from "./corelogic/usecases/simulations-display/simulationsDisplayGateway.interface";
import {AuthenticationGatewayInterface} from "./corelogic/usecases/authentication/authenticationGateway.interface";
import {NewSimulationGatewayInterface} from "./corelogic/usecases/new-simulation/newSimulationGateway.interface";
import {AuthenticationApiGateway} from "./adapters/secondary/gateways/Api/AuthenticationApiGateway";
import {
    PhysicalRestitutionGatewayInterface
} from "./corelogic/usecases/physicalRestitution/physicalRestitutionGateway.interface";
import {
    FinancialRestitutionGatewayInterface
} from "./corelogic/usecases/financialRestitution/financialRestitutionGateway.interface";
import {PhysicalRestitutionInApiGateway} from "./adapters/secondary/gateways/Api/PhysicalRestitutionInApiGateway";
import {APINewSimulationGateway} from "./adapters/secondary/gateways/APINewSimulationGateway";
import {APISimulationsDisplayGateway} from "./adapters/secondary/gateways/APISimulationsDisplayGateway";
import {FinancialRestitutionInApiGateway} from "./adapters/secondary/gateways/Api/FinancialRestitutionInApiGateway";
import {RestitutionGatewayInterface} from "./corelogic/usecases/restitution/restitutionGateway.interface";
import {RestitutionApiGateway} from "./adapters/secondary/gateways/Api/RestitutionApiGateway";
import {
    SimulationsDisplayInMemoryGateway
} from "./adapters/secondary/gateways/InMemory/SimulationsDisplayInMemoryGateway";
import {AuthenticationInMemoryGateway} from "./adapters/secondary/gateways/InMemory/AuthenticationInMemoryGateway";
import {
    PhysicalRestitutionInMemoryGateway
} from "./adapters/secondary/gateways/InMemory/PhysicalRestitutionInMemoryGateway";
import {InMemoryNewSimulationGateway} from "./adapters/secondary/gateways/InMemoryNewSimulationGateway";
import {
    FinancialRestitutionInMemoryGateway
} from "./adapters/secondary/gateways/InMemory/FinancialRestitutionInMemoryGateway";

const useInMemory = process.env.REACT_APP_USE_IN_MEMORY === 'true';
export type Dependencies = {
    simulationsDisplayGatewayContext?: SimulationsDisplayGatewayInterface,
    authenticationGatewayContext?: AuthenticationGatewayInterface,
    newSimulationGatewayContext?: NewSimulationGatewayInterface,
    physicalRestitutionGatewayContext?: PhysicalRestitutionGatewayInterface,
    financialRestitutionGatewayContext?: FinancialRestitutionGatewayInterface,
    restitutionGatewayContext?: RestitutionGatewayInterface,
}

export const dependencies: Dependencies = {
    simulationsDisplayGatewayContext: useInMemory ? new SimulationsDisplayInMemoryGateway() : new APISimulationsDisplayGateway(),
    authenticationGatewayContext: useInMemory ? new AuthenticationInMemoryGateway() : new AuthenticationApiGateway(),
    physicalRestitutionGatewayContext: useInMemory ? new PhysicalRestitutionInMemoryGateway() : new PhysicalRestitutionInApiGateway(),
    newSimulationGatewayContext: useInMemory ? new InMemoryNewSimulationGateway() : new APINewSimulationGateway(),
    financialRestitutionGatewayContext: useInMemory ? new FinancialRestitutionInMemoryGateway() : new FinancialRestitutionInApiGateway(),
    restitutionGatewayContext: new RestitutionApiGateway(),
}