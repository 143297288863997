import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './adapters/primary/ui/App'

import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {createReduxStore} from "./store/store";
import {dependencies} from "./dependencies";
import {createBrowserHistory} from 'history';
import {ConnectedRouter} from "connected-react-router";
import {SnackbarProvider} from "notistack";
import ChatwootWidget from "./adapters/primary/ui/components/support/ChatwootWidget";

const history = createBrowserHistory();
const store = createReduxStore(dependencies, history)

const script = document.createElement("script");
script.src = "https://cloud.umami.is/script.js";
script.async = true;
script.setAttribute("data-website-id", process.env.REACT_APP_UMAMI_ID || "");
document.head.appendChild(script);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <SnackbarProvider anchorOrigin={{vertical: 'top', horizontal: 'center'}} preventDuplicate={true}
                              maxSnack={3}>
                <App/>
                {process.env.REACT_APP_CHATWOOT_TOKEN && <div className={"relative top-8"}><ChatwootWidget/></div>}
            </SnackbarProvider>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// expose store when run in Cypress
const win:any = window
if (win.Cypress) {
    win.store = store
}
