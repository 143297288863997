import data from '../../../../adapters/primary/ui/utils/data.json'
import {
    PhysicalRestitutionGatewayInterface
} from "../../../../corelogic/usecases/physicalRestitution/physicalRestitutionGateway.interface";
import {CDCNature, metricsNature} from "../../../../corelogic/models/types/physicalRestitution/NatureEnum";

export class PhysicalRestitutionInMemoryGateway implements PhysicalRestitutionGatewayInterface {
    private simulationData: any;

    constructor() {
        this.simulationData = data
    }

    getCDC = (simulationId: string, nature: string, type: string): any => {
        let values: any;
        switch (nature) {
            //global
            case CDCNature.totalConsoNetwork:
                values = this.simulationData.acc['cdc globale']['Consommation totale']
                break;
            case CDCNature.totalConsoAcc:
                values = this.simulationData.acc['cdc globale']['Autoconsommation collective']
                break;
            case CDCNature.totalConso:
                values = this.simulationData.aci['cdc globale']['Consommation totale']
                break;
            case CDCNature.totalConsoAc:
                values = this.simulationData.aci['cdc globale']['Autoconsommation collective']
                break;
            case CDCNature.totalExcess:
                values = this.simulationData.acc['cdc globale']['Surplus']
                break;


            // producer
            case CDCNature.prodAcc:
                values = this.simulationData.acc.injectionPoints[type].cdc['Autoconsommation collective']
                break;
            case CDCNature.prodAc:
                values = this.simulationData.aci.injectionPoints[type].cdc['Autoconsommation collective']
                break;
            case CDCNature.prodNetwork:
                values = this.simulationData.acc.injectionPoints[type].cdc['Production total']
                break;
            case CDCNature.prod:
                values = this.simulationData.aci.injectionPoints[type].cdc['Production total']
                break;
            case CDCNature.excess:
                values = this.simulationData.acc.injectionPoints[type].cdc['Surplus']
                break;


            // consumer
            case CDCNature.consoAcc:
                values = this.simulationData.acc.cunsumptionPoints[type]?.cdc['Autoconsommation collective'];
                break;
            case CDCNature.consoAc:
                values = this.simulationData.aci.cunsumptionPoints[type]?.cdc['Autoconsommation collective'];
                break;
            case CDCNature.consoNetwork:
                values = this.simulationData.acc.cunsumptionPoints[type]?.cdc['Consommation totale'];
                break;
            case CDCNature.conso:
                values = this.simulationData.aci.cunsumptionPoints[type]?.cdc['Consommation totale'];
                break;
            default:
                values = [];
        }
        return this.simulationData.acc['cdc globale'].timestamps.map((time: any, idx: any) => ({
            time,
            value: values[idx] || 0
        }));
    }

    getCDCGroupedByMonth = (simulationId: string, nature: string): any => {
        let values: any;
        switch (nature) {
            //global
            case CDCNature.totalConsoNetwork:
                values = this.simulationData.acc['cdc mensuelle']['Consommation totale']
                break;
            case CDCNature.totalConsoAcc:
                values = this.simulationData.acc['cdc mensuelle']['Autoconsommation collective']
                break;
            case CDCNature.totalConsoAc:
                values = this.simulationData.aci['cdc mensuelle']['Autoconsommation collective']
                break;
            case CDCNature.totalExcess:
                values = this.simulationData.acc['cdc mensuelle']['surplus']
                break;
            default:
                values = [];
        }
        return this.simulationData.acc['cdc mensuelle'].timestamps.map((time: any, idx: any) => ({
            time,
            value: values[idx] || 0
        }));
    }

    getMetricValue = (simulationId: string, nature: string, pointId?: string): Promise<number> | number => {
        if (pointId) {
            let value: number;
            switch (nature) {
                //global
                case metricsNature.totalConsoAcc:
                    value = this.simulationData.acc['pieChart cunsumption']['Autoconsommation du collectif']
                    break;
                case metricsNature.totalConsoAci:
                    value = this.simulationData.aci['pieChart cunsumption']['Autoconsommation directe SG']
                    break;
                case metricsNature.totalConsoSupplier:
                    value = this.simulationData.aci['pieChart cunsumption']['Fourniture de complément globale']
                    break;
                case metricsNature.totalConsoSupplierAcc:
                    value = this.simulationData.acc['pieChart cunsumption']['Complément du réseau']
                    break;
                case metricsNature.totalExcess:
                    value = this.simulationData.acc['pieChart production']['Surplus']
                    break;
                case metricsNature.accAutoproductionRate:
                    value = pointId === 'Etude globale' ? this.simulationData.acc['Taux d\'autoproduction du collectif'] :
                        this.simulationData.acc.cunsumptionPoints[pointId]['Taux d\'autoproduction du collectif']
                    break;
                case metricsNature.aciAutoproductionRate:
                    value = pointId === 'Etude globale' ? this.simulationData.aci['Taux d\'autoproduction du collectif'] :
                        this.simulationData.aci.cunsumptionPoints[pointId]['Taux d\'autoproduction du collectif']
                    break;
                case metricsNature.accAutoconsumptionRate:
                    value = pointId === 'Etude globale' ? this.simulationData.acc['Taux d\'autoconsommation du collectif'] :
                        this.simulationData.acc.injectionPoints[pointId]['Taux d\'autoconsommation du collectif']
                    break;
                case metricsNature.aciAutoconsumptionRate:
                    value = pointId === 'Etude globale' ? this.simulationData.aci['Taux d\'autoconsommation du collectif'] :
                        this.simulationData.aci.injectionPoints[pointId]['Taux d\'autoconsommation du collectif']
                    break;


                // producer
                case metricsNature.prodAcc:
                    value = this.simulationData.acc.injectionPoints[pointId]['pieChart']['Autoconsommation du collectif']
                    break;
                case metricsNature.prodAci:
                    value = this.simulationData.aci.injectionPoints[pointId]['pieChart']['Autoconsommation directe']
                    break;
                case metricsNature.excess:
                    value = this.simulationData.acc.injectionPoints[pointId]['pieChart']['Surplus']
                    break;


                // consumer
                case metricsNature.consoAcc:
                    value = this.simulationData.acc.cunsumptionPoints[pointId]['pieChart']['Autoconsommation du collectif'];
                    break;
                case metricsNature.consoAci:
                    value = this.simulationData.aci.cunsumptionPoints[pointId]['pieChart']['Autoconsommation directe'];
                    break;
                case metricsNature.consoSupplier:
                    value = this.simulationData.aci.cunsumptionPoints[pointId]['pieChart']['Complément du réseau'];
                    break;
                default:
                    value = 0;
            }
            return value || 0
        }
        return 0;
    }

}
