import React, {useEffect, useRef, useState} from "react";
import {WrapperCard} from "../wrapper/WrapperCard";
import {useSelector} from "react-redux";

import {
    selectRestitutionData, selectRestitutionLoading
} from "../../../../../corelogic/usecases/restitution/restitutionSelectors";
import {formatNumber} from "../../../../../corelogic/usecases/config";
import {InformationCircleIconOrangeOnHover} from "../../components/assets/icon/InformationCircleIcon";
import { selectConsumptionPoints } from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectConsumptionPointForm";
import { selectFinancialDuration } from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";


function ConsumptionsRecapTable({isOpenOperation}:  {isOpenOperation: boolean}) {
    const restitution = useSelector(selectRestitutionData)
    const restitution_loading = useSelector(selectRestitutionLoading);
    const consumptionPoints = useSelector(selectConsumptionPoints)
    const duration = useSelector(selectFinancialDuration)
    const consoRecapTableRef = useRef(null)
    const [consumptionPointsIds, setConsumptionPointsIds] = useState<string[]>([])
    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
        if (restitution.physics_metrics) {
            setConsumptionPointsIds(Object.keys(restitution.physics_metrics.consumption_points_metrics))
        }
    }, [restitution])

    useEffect(() => {
        setDataLoaded(restitution.physics_metrics && !restitution_loading)
    }, [restitution, restitution_loading])

    const priceElement = (peakOffPeakHoursDefinition: any): string => {
        if (peakOffPeakHoursDefinition?.pricing?.peakPricing?.offPeakProviderKwhPrice){
            const hp = peakOffPeakHoursDefinition.pricing.peakPricing.peakKwhProviderPrice
            const hc = peakOffPeakHoursDefinition.pricing.peakPricing.offPeakProviderKwhPrice
            return `${hp} (HP), ${hc} (HC)`
        }
        if (peakOffPeakHoursDefinition?.pricing?.peakPricing?.summerOffPeakKwhProviderPrice) {
            const hpe = peakOffPeakHoursDefinition.pricing.peakPricing.summerPeakKwhProviderPrice
            const hce = peakOffPeakHoursDefinition.pricing.peakPricing.summerOffPeakKwhProviderPrice
            const hph = peakOffPeakHoursDefinition.pricing.peakPricing.winterPeakKwhProviderPrice
            const hch = peakOffPeakHoursDefinition.pricing.peakPricing.winterOffPeakKwhProviderPrice
            return `${hpe} (HPE), ${hce} (HCE), ${hph} (HPH), ${hch} (HCH)`
        }
        return `${peakOffPeakHoursDefinition}`
    }

    const rowStyle = "py-1 px-4 border-r dark:border-zinc-800 whitespace-nowrap"

    const global_metrics = restitution.physics_metrics?.global_metrics
    const global_consumption = global_metrics ? global_metrics.total_conso_supplier + global_metrics.total_conso_aci + global_metrics.total_conso_acc : 0
    let ACCFirstYearSaving: number = 0
    let ACIFirstYearSaving: number = 0
    let ACSaving: number = 0
    return <WrapperCard
        exportAsPNGRef={consoRecapTableRef}
        title={"Consommations"} exportTableId={'conso-recap-table'}>
        <div ref={consoRecapTableRef} className=" bg-white shadow-md rounded dark:bg-zinc-700 overflow-x-auto">
            <table id={'conso-recap-table'} className="w-full table-auto rounded-lg">
                <thead className={'bg-white dark:bg-zinc-700'}>
                <tr className="text-gray-500 text-xs leading-normal dark:text-zinc-400 border-b dark:border-zinc-800 ">
                    <th align={"center"} rowSpan={2} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">Site</th>
                    <th align={"center"}  rowSpan={2} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">Segment</th>
                    <th align={"center"} colSpan={4} className="py-1 font-semibold px-4 border-r dark:border-zinc-800">Consommation (kWh)</th>
                    <th align={"center"} colSpan={3} className="py-1 font-semibold px-4 border-r dark:border-zinc-800">Taux auto-production (%)</th>
                    <th align={"center"} rowSpan={2} className="py-1 font-semibold px-4 border-r dark:border-zinc-800">Part de la production consommée (%)</th>
                    <th align={"center"} rowSpan={2} className="py-1 font-semibold px-4 border-r dark:border-zinc-800">Tarif élec (€/kWh)</th>
                    <th align={"center"} colSpan={2} className="py-1 font-semibold px-4 border-r dark:border-zinc-800">Economie annee 1 (€)</th>
                    <th align={"center"} rowSpan={2} className="py-1 font-semibold px-4">Economie année {duration} (€)</th>
                </tr>
                <tr className="text-gray-500 text-xs leading-normal dark:text-zinc-400 border-b dark:border-zinc-800 ">
                    <th align={"center"} className="py-1 font-semibold px-4 border-r dark:border-zinc-800">globale (annuelle) </th>
                    <th align={"center"} className="py-1 font-semibold px-4 border-r dark:border-zinc-800">réseau </th>
                    <th align={"center"} className="py-1 font-semibold px-4 border-r dark:border-zinc-800">ACC </th>
                    <th align={"center"} className="py-1 font-semibold px-4 border-r dark:border-zinc-800">ACI </th>
                    <th align={"center"} className="py-1 font-semibold px-4 border-r dark:border-zinc-800">global </th>
                    <th align={"center"} className="py-1 font-semibold px-4 border-r dark:border-zinc-800">ACC </th>
                    <th align={"center"} className="py-1 font-semibold px-4 border-r dark:border-zinc-800">ACI </th>
                    <th align={"center"} className="py-1 font-semibold px-4 border-r dark:border-zinc-800">ACC</th>
                    <th align={"center"} className="py-1 font-semibold px-4 border-r dark:border-zinc-800">ACI</th>
                    
                </tr>
                </thead>
                <tbody className="text-gray-500 text-xs font-light dark:text-zinc-400 bg-white dark:bg-zinc-700">
                {dataLoaded ? consumptionPointsIds.map(cp_id => {
                    const cp = consumptionPoints.find(cp => cp.id === cp_id)
                    const cp_metrics = restitution?.physics_metrics.consumption_points_metrics[cp_id]
                    const cp_savings = restitution?.savings.consumption_points_savings[cp_id]
                    const cp_consumption = cp_metrics.conso_supplier + cp_metrics.conso_aci + cp_metrics.conso_acc
                    ACCFirstYearSaving +=  cp_savings.collective_ac_first_year_saving
                    ACIFirstYearSaving += cp_savings.individual_ac_first_year_saving || 0
                    ACSaving += cp_savings.ac_saving
                    return <tr className={' hover:bg-gray-100 dark:hover:bg-zinc-600'} key={cp_id}>
                        <td align={"center"} className={rowStyle + " overflow-hidden overflow-ellipsis max-w-[150px]"}>
                            <div title={`${cp?.name}${cp_savings.number > 1 ? ' (par site)' : ''}`} className="text-sm dark:text-zinc-400 overflow-hidden overflow-ellipsis max-w-[150px]">
                                {`${cp?.name}${cp_savings.number > 1 ? ' (par site)' : ''}`}
                            </div>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span className="text-sm dark:text-zinc-400">{cp?.segment}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span className="text-sm dark:text-zinc-400">
                                {formatNumber((cp_metrics.conso_supplier + cp_metrics.conso_acc + cp_metrics.conso_aci) / 1000)}
                            </span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span className="text-sm dark:text-zinc-400">
                                {formatNumber(cp_metrics.conso_supplier / 1000)}
                            </span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span className="text-sm dark:text-zinc-400">
                                {formatNumber(cp_metrics.conso_acc / 1000)}
                            </span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span className="text-sm dark:text-zinc-400">
                                {formatNumber(cp_metrics.conso_aci / 1000)}
                            </span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                        <span className="text-sm dark:text-zinc-400">
                            {formatNumber(cp_metrics.aci_autoproduction_rate || cp_metrics.acc_autoproduction_rate, 1)}
                        </span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                        <span className="text-sm dark:text-zinc-400">
                            {formatNumber(cp_metrics.conso_acc / cp_consumption * 100, 1)}
                        </span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                        <span className="text-sm dark:text-zinc-400">
                            {formatNumber(cp_metrics.conso_aci / cp_consumption * 100, 1)}
                        </span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span className="text-sm dark:text-zinc-400">
                                {formatNumber((cp_metrics.conso_acc + cp_metrics.conso_aci) / global_metrics.total_prod * 100, 1)}
                            </span>
                        </td>
                        <td align={"center"} className={rowStyle + " overflow-hidden overflow-ellipsis max-w-[150px]"}>
                            <div title={priceElement(cp?.complementProviderKwhPrice.peakOffPeakHoursDefinition)} className="text-sm dark:text-zinc-400 overflow-hidden overflow-ellipsis max-w-[150px]">
                                {priceElement(cp?.complementProviderKwhPrice.peakOffPeakHoursDefinition)}
                            </div>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span className="text-sm dark:text-zinc-400">
                                {formatNumber(isOpenOperation ? cp_savings.collective_ac_first_year_saving / cp_savings.number : cp_savings.collective_ac_first_year_saving_by_unit)}
                            </span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span className="text-sm dark:text-zinc-400">
                                {formatNumber(cp_savings.individual_ac_first_year_saving)}
                            </span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span className="text-sm dark:text-zinc-400">
                                {formatNumber(cp_savings.ac_saving / cp_savings.number)}
                            </span>
                        </td>
                    </tr>
                }) :
                    <tr className="border-b border-gray-200 animate-pulse">
                        {[...Array(15)].map(() => {
                            return <td className="py-2 px-4 text-left whitespace-nowrap">
                                <div className="flex items-left">
                                    <span className="font-bold bg-gray-100 h-4 w-full dark:bg-zinc-600"/>
                                </div>
                            </td>
                        })}
                    </tr>}
                {dataLoaded && <tr className={' hover:bg-gray-100 font-semibold dark:hover:bg-zinc-600 bg-green-300 hover:bg-green-400 dark:bg-green-900 dark:hover:bg-green-800'} key={"row-total"}>
                    <td align={"center"} className={rowStyle}>
                        <div
                            title={"S'il y a des sites non unitaires, la somme des économies des sites sur cette colonne ne sera pas égale à cette valeur affichée. Multipliez la valeur du site par son nombre pour retrouver l'économie totale."}
                            className="text-sm dark:text-zinc-400 flex justify-center gap-1">
                            TOTAL Projet
                            <InformationCircleIconOrangeOnHover/>
                        </div>
                    </td>
                    <td className={rowStyle}/>
                    <td align={"center"} className={rowStyle}>
                        <span className="text-sm dark:text-zinc-400">
                            {formatNumber((global_metrics.total_conso_supplier + global_metrics.total_conso_acc + global_metrics.total_conso_aci) / 1000)}
                        </span>
                    </td>
                    <td align={"center"} className={rowStyle}>
                        <span className="text-sm dark:text-zinc-400">
                            {formatNumber(global_metrics.total_conso_supplier / 1000)}
                        </span>
                    </td>
                    <td align={"center"} className={rowStyle}>
                        <span className="text-sm dark:text-zinc-400">
                            {formatNumber(global_metrics.total_conso_acc / 1000)}
                        </span>
                    </td>
                    <td align={"center"} className={rowStyle}>
                        <span className="text-sm dark:text-zinc-400">
                            {formatNumber(global_metrics.total_conso_aci / 1000)}
                        </span>
                    </td>
                    <td align={"center"} className={rowStyle}>
                        <span className="text-sm dark:text-zinc-400">
                            {formatNumber(global_metrics.aci_autoproduction_rate, 1)}
                        </span>
                    </td>
                    <td align={"center"} className={rowStyle}>
                        <span className="text-sm dark:text-zinc-400">
                            {formatNumber(global_metrics.total_conso_acc / global_consumption * 100, 1)}
                        </span>
                    </td>
                    <td align={"center"} className={rowStyle}>
                        <span className="text-sm dark:text-zinc-400">
                            {formatNumber(global_metrics.total_conso_aci / global_consumption * 100, 1)}
                        </span>
                    </td>
                    <td align={"center"} className={rowStyle}>
                        <span className="text-sm dark:text-zinc-400">
                            {formatNumber(global_metrics.aci_autoconsumption_rate, 1)}
                        </span>
                    </td>
                    <td className={rowStyle}/>
                    <td align={"center"} className={rowStyle}>
                        <span className="text-sm dark:text-zinc-400">
                            {formatNumber(ACCFirstYearSaving)}
                        </span>
                    </td>
                    <td align={"center"} className={rowStyle}>
                        <span className="text-sm dark:text-zinc-400">{formatNumber(ACIFirstYearSaving)}</span>
                    </td>
                    <td align={"center"} className={rowStyle}>
                        <span className="text-sm dark:text-zinc-400">{formatNumber(ACSaving)}</span>
                    </td>
                </tr>}
                </tbody>
            </table>
        </div>
    </WrapperCard>
}

export {ConsumptionsRecapTable}
