import {Curve, Measurement, StatusEnum} from "../../../../../../../corelogic/models/types/new-simulation/curve/Curve";


export class LoadCurveBuilder {
    private measurement: Measurement[] = []
    private startDate: Date = new Date('01/01/2022 00:00')
    private endDate: Date = new Date('01/01/2023 00:00')
    private step_length: number = 30
    private status: StatusEnum = StatusEnum.STANDARD_COMPLETION_COMPLIANT
    private id:string | null = null
    private recalageInput: number = 1000
    private holes : {dateStartHole:Date, dateEndHole:Date}[] = []

    private maxNOfDaysWithContiguousData = 365
    private dataCoversTargetYearWith30mnSteps = false

    constructor() {
    }
    withMaxNOfDaysWithContiguousData(nbDays : number){
        this.maxNOfDaysWithContiguousData = nbDays
        return this
    }
    withDataCoversTargetYearWith30mnSteps(targetYearAnd30MinSteps:boolean){
        this.dataCoversTargetYearWith30mnSteps = targetYearAnd30MinSteps
        return this
    }
    withStepLength(stepLength:number){
        this.step_length = stepLength
        return this
    }
    withStartDate(startDate: string) {
        this.startDate = new Date(startDate)
        return this
    }

    withEndDate(endDate: string) {
        this.endDate = new Date(endDate)
        return this
    }

    withStatus(status:StatusEnum){
        this.status= status;
        return this
    }
    withId(id:string){
        this.id= id;
        return this
    }
    calculateIterations(): number {
        // Parse the input dates
        const startDateObj = this.startDate
        const endDateObj = this.endDate

        // Convert the interval from minutes to milliseconds
        const intervalMilliseconds = this.step_length * 60 * 1000;

        // Calculate the time difference in milliseconds
        const timeDifference = endDateObj.getTime() - startDateObj.getTime();
        // Calculate the number of iterations
        const iterations = Math.floor(timeDifference / intervalMilliseconds);

        return iterations;
    }
    withHole(startDateHole : Date, endDateHole : Date){
        this.holes.push({dateStartHole:startDateHole, dateEndHole:endDateHole})
        return this
    }


    generateHoles(){
        if(this.holes.length > 0){
            this.holes.forEach((hole:{dateStartHole:Date, dateEndHole:Date}) => {
                this.measurement.map((measure)=> {
                    const currDate = new Date(measure.timestamp)
                    if( currDate>= hole.dateStartHole && currDate <= hole.dateEndHole){
                        measure.value = null
                    }
                })
            })
        }
    }
    private generateMeasurements() {

        const totalItems = this.calculateIterations();
        const measurements: Measurement[] = [];
        for (let i = 0; i < totalItems; i++) {
            const date = new Date(this.startDate.getTime() + i * this.step_length * 60 * 1000);
            const power = parseInt((Math.random() * this.recalageInput).toFixed(2)); // Replace this with your actual power calculation
            measurements.push({timestamp: date.toISOString(), value: power});
        }
        this.measurement = measurements;
        this.generateHoles()
    }

    build() {
        this.generateMeasurements()
        return new Curve(this.id, this.measurement, 50,500,  this.status, this.maxNOfDaysWithContiguousData, this.step_length, this.dataCoversTargetYearWith30mnSteps);
    }

    applyRandomRecalage() {
        this.recalageInput = Math.random() * 2000
        return this
    }

    buildExcelFile(): FormData {
        let excelData = `Datetime (timezoneUTC)\tPuissance (W)\n`;
        // Iterate over each day between the start and end dates
        let currentDate = new Date(this.startDate.toISOString());
        while (currentDate <= this.endDate) {
            const formattedDate = currentDate.toLocaleString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZone: 'UTC',
            });
            const row = `${formattedDate}\t50,5\n`;
            excelData += row;

            // Move to the next day
            currentDate.setDate(currentDate.getDate() + 1);
        }
        const blob = new Blob([excelData], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

        // Create a FormData object and append the blob to it
        const formData = new FormData();
        formData.append('excelFile', blob, 'sample.xlsx');
        return formData
    }

}

