import {useDispatch, useSelector} from "react-redux";
import {
    selectAddresses,
    selectTypesOfAddress
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import React, {useCallback, useEffect, useState} from "react";
import {newSimulationActions} from "../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {AddressGouv} from "../../../../../../corelogic/models/types/new-simulation/external-api/AddressGouv";
import OutsideAlerter from "../../wrapper/OutsideAlerter";
import TextInput from "../../../components/assets/FormAssets/TextInput";
import RainbowLoader from "../../../components/assets/Animated/RainbowLoader";
import {
    selectIsLoadingAddressAPI,
    selectIsLoadingSimulationForm
} from "../../../../../../corelogic/usecases/loader/loaderSelector";
import {makeIsPostcodeValid} from "../../../../../../corelogic/usecases/new-simulation/ValidatorsSelector";

export default function PostCodeInput({
                                          postcode,
                                          setPostcode,
                                          generalInfo
                                      }: { postcode: string, setPostcode(p: string): void, generalInfo: any }) {
    const dispatch = useDispatch()
    const addresses = useSelector(selectAddresses)
    const typeOfAddresses = useSelector(selectTypesOfAddress)
    const isLoading = useSelector(selectIsLoadingAddressAPI)
    const [addressesListDropDown, setAddressesListDropDown] = useState(false)
    const [lockAddressesListDisabled, setLockAddressesListDisabled] = useState(false)
    const isFormLoading  = useSelector(selectIsLoadingSimulationForm)
    const isPostCodeIsValid = useCallback(makeIsPostcodeValid(postcode),[postcode])
    const postCodeIsValid = useSelector(isPostCodeIsValid)

    useEffect(() => {
        dispatch(newSimulationActions.initAddressFromAPI())
    }, [])


    useEffect(() => {
        disableOnPageRefresh()
    }, [generalInfo])
    useEffect(() => {
        if (postcode.length > 0 && !lockAddressesListDisabled) {
            dispatch(newSimulationActions.getAddressFromAPI(postcode, ["municipality"]))
            setAddressesListDropDown(true)
            setLockAddressesListDisabled(false)
        }
    }, [postcode, lockAddressesListDisabled])


    function addressOnChange(e: string) {
        setPostcode(e);
    }


    function handleClickOnAddress(address: AddressGouv) {
        setPostcode(address.label + ', '+address.context)
        dispatch(newSimulationActions.setAddressFromAPI([address]))
        setLockAddressesListDisabled(true)
    }

    function translateAddressType(type: string) {
        switch (type) {
            case 'municipality' :
                return 'Commune';
            case 'street' :
                return 'Rue';
            case 'housenumber' :
                return "Numéro d'habitation";
            default :
                return type
        }
    }

    function disableOnPageRefresh() {
        if (generalInfo?.postcode) {
            setLockAddressesListDisabled(true)
        }
    }

    return <OutsideAlerter clickOutsideAction={() => setAddressesListDropDown(false)}
                           className={"w-full"}>
        <div className={"w-full"}>
            <div className={"w-full"}>
                <TextInput loading={isFormLoading} onFocus={() => {
                    setLockAddressesListDisabled(false)
                    setAddressesListDropDown(true)
                }} mandatory={true}
                           label={"COMMUNE (code postal)"}
                           placeholder={"(ex : 38000; Grenoble)"}
                           id={"step1-postCode"}
                           dataCy={"general-info-postcode"}
                           onChange={addressOnChange} value={postcode} error={{
                    state: !postCodeIsValid && !isLoading,
                    msg: postcode.length === 1 ? "Saisir au moins 2 caractères pour effectuer la recherche" :"Sélectionnez une adresse valide dans la liste de résultats."
                }}/>
                {!lockAddressesListDisabled && postcode.length >1 && addressesListDropDown && <div className={"bg-slate-200 max-h-[250px] overflow-y-auto border-2 border-slate-300 font-bold text-slate-500 rounded w-full "}>
                    {isLoading ? <RainbowLoader text={"Recherche de la commune"}/>
                        : addressesListDropDown && <div className={"h-max-[200px] h-min w-full"}>
                        {
                            addresses.length > 0 ? Array.from(typeOfAddresses).map((typeOfAddresses: string) => {
                                    return <div data-cy={"general-info-addresses-list"} className={"bg-slate-400 w-full  text-slate-50 flex flex-col "}>
                                        <span className={"px-1 py-1 font-medium"}>{translateAddressType(typeOfAddresses)}</span>
                                        {
                                            addresses.map((address: AddressGouv, index) => {
                                                if (address.type === typeOfAddresses ) {
                                                    return <div
                                                        data-cy={index === 0 && 'general-info-addresses-first-result'}
                                                        onClick={() => handleClickOnAddress(address)}
                                                        className={"bg-slate-200 px-2 py-1 font-semibold  text-gray-700 hover:cursor-pointer hover:bg-slate-300 flex justify-between items-align"}>
                                                        <span>{address.type !== "municipality" ? address.name + "," : ""} {address.city}</span>
                                                        <span>{address.context}</span></div>
                                                }
                                            })
                                        }
                                    </div>
                                })
                                :
                                <span className={"text-slate-500 px-2 font-medium text-sm"}>Aucune adresse trouvée, vérifiez qu'il n'y a pas d'erreur de saisie ou que l'information est assez spécifique</span>
                        }
                    </div>
                    }
                </div>}
            </div>
        </div>
    </OutsideAlerter>
}
