import {useDispatch, useSelector} from "react-redux";
import {
    selectAddresses,
    selectTypesOfAddress
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form/newSimulationSelector";
import React, {useEffect, useState} from "react";
import {newSimulationActions} from "../../../../../../corelogic/usecases/new-simulation/newSimulationActions";
import {AddressGouv} from "../../../../../../corelogic/models/types/new-simulation/external-api/AddressGouv";
import OutsideAlerter from "../../wrapper/OutsideAlerter";
import TextInput from "../../../components/assets/FormAssets/TextInput";
import RainbowLoader from "../../../components/assets/Animated/RainbowLoader";
import {
    selectIsLoadingAddressAPI,
    selectIsLoadingSimulationForm
} from "../../../../../../corelogic/usecases/loader/loaderSelector";
import {selectAddress} from "../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
import {typologyFormActions} from "../../../../../../corelogic/usecases/typology-form/typologyFormActions";

export default function TypologyPointAddressInput({
                           addressIsValid,
                           setAddressIsValid
                       }: { addressIsValid: boolean, setAddressIsValid(p:boolean):void}) {
    const dispatch = useDispatch()
    const addressForm = useSelector(selectAddress)
    const addresses = useSelector(selectAddresses)
    const typeOfAddresses = useSelector(selectTypesOfAddress)
    const isLoading = useSelector(selectIsLoadingAddressAPI)
    const isFormLoading = useSelector(selectIsLoadingSimulationForm)
    const [addressesListDropDown, setAddressesListDropDown] = useState(false)
    const [lockAddressesListDisabled, setLockAddressesListDisabled] = useState(false)

    useEffect(() => {
        dispatch(newSimulationActions.initAddressFromAPI())
    }, [])

    useEffect(() => {
        if (addressForm && addresses.find((add: AddressGouv) => add.label === addressForm )) {
            setAddressIsValid(true)
        } else if(addressForm.length > 0){
            setAddressIsValid(false)
        }
    }, [addressForm, addresses])
    useEffect(() => {
        if (addressForm) {
            dispatch(newSimulationActions.getAddressFromAPI(addressForm, ["housenumber", "street"]))
            setAddressesListDropDown(true)
        }
    }, [addressForm])


    function addressOnChange(e: string) {
        dispatch(typologyFormActions.setAddress(e));
        setLockAddressesListDisabled(false)
    }


    function handleClickOnAddress(addressForm: AddressGouv) {
        dispatch(typologyFormActions.setAddress(addressForm.label))
        setLockAddressesListDisabled(true)
    }

    function translateAddressType(type: string) {
        switch (type) {
            case 'municipality' :
                return 'Commune';
            case 'street' :
                return 'Rue';
            case 'housenumber' :
                return "Numéro d'habitation";
            default :
                return type
        }
    }

    return <OutsideAlerter clickOutsideAction={() => setAddressesListDropDown(false)}
                           className={"w-full"}>
        <div className={"w-full"}>
            <div className={"w-full"}>
                <TextInput onFocus={() => {
                    setLockAddressesListDisabled(false)
                    setAddressesListDropDown(true)
                }} mandatory={false} loading={isFormLoading}
                           label={"ADRESSE (facultative, permet d'afficher le site sur la carte)"}
                           placeholder={"(ex : 38000 ; Grenoble ; 5 rue Voltaire)"}
                           id={"inj-addressForm"}
                           dataCy={"injection-point-addressForm"}
                           onChange={addressOnChange} value={addressForm} error={{
                    state: !addressIsValid && !isLoading,
                    msg: addressForm.length === 1 ? "Saisir au moins 2 caractères pour effectuer la recherche" :"Sélectionnez une adresse valide dans la liste de résultats."
                }}/>
                {!lockAddressesListDisabled && addressForm.length >1 && addressesListDropDown && <div className={"bg-slate-200 border-2 border-slate-300 font-bold text-slate-500 rounded w-full "}>
                    {isLoading ? <RainbowLoader text={"Recherche de la commune"}/>
                        : addressesListDropDown && <div className={"max-h-[200px] overflow-y-auto h-min w-full"}>
                        {
                            addresses.length > 0 ? Array.from(typeOfAddresses).map((typeOfAddresses: string) => {
                                    return <div data-cy={"general-info-addresses-list"} className={"bg-slate-400 w-full  text-slate-50 flex flex-col "}>
                                        <span className={"px-1 py-1 font-medium"}>{translateAddressType(typeOfAddresses)}</span>
                                        {
                                            addresses.map((addressForm: AddressGouv, index) => {
                                                if (addressForm.type === typeOfAddresses ) {
                                                    return <div
                                                        data-cy={index === 0 && 'general-info-addresses-first-result'}
                                                        onClick={() => handleClickOnAddress(addressForm)}
                                                        className={"bg-slate-200 px-2 py-1 font-semibold  text-gray-700 hover:cursor-pointer hover:bg-slate-300 flex justify-between items-align"}>
                                                        <span>{addressForm.type !== "municipality" ? addressForm.name + "," : ""} {addressForm.city}</span>
                                                        <span>{addressForm.context}</span></div>
                                                }
                                            })
                                        }
                                    </div>
                                })
                                :
                                <span className={"text-slate-500 px-2 font-medium text-sm"}>Aucune adresse trouvée, vérifiez qu'il n'y a pas d'erreur de saisie ou que l'information est assez spécifique</span>
                        }
                    </div>
                    }
                </div>}
            </div>
        </div>
    </OutsideAlerter>
}
