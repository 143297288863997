import {useSelector} from "react-redux";
import {selectAutoproductionRate} from "../../../../../corelogic/usecases/physicalRestitution/physicalRestitutionSelector";

export default function () {
    const autoproductionRate = useSelector(selectAutoproductionRate)
    return (<div className="relative pt-1 w-full">
        <div className="w-full flex justify-between">
            <div className="font-extrabold text-xl">{autoproductionRate.toFixed(1)}%</div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded  bg-[#84a6cf] h-4">
            <div className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center  bg-[#f3b617]"
                 style={{width: `${autoproductionRate}%`}}></div>
        </div>
    </div>)
}