import React, {Suspense, useState} from "react";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import TitleText from "../../../components/assets/text/TitleText";
import DescriptionText from "../../../components/assets/text/DescriptionText";
import SingleBtnToggle from "../../../components/assets/toggle/SingleBtnToggle";
import {MapIconSolid} from "../../../components/assets/icon/MapIcon";
import {
    atLeast1ActiveInjectionPointsWithAddress
} from "../../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";
import Loading from "../../map/leaflet-map/loading/Loading";
import {selectShowCartoDensity} from "../../../../../../corelogic/usecases/typology-form/typologyFormSelector";
const LeafletControls = React.lazy(() => import("../../map/leaflet-map/layers/LeafletControls"));
const LeafletMap = React.lazy(() => import("../../map/leaflet-map/LeafletMap"));
const LeafletMapWithCommunes = React.lazy(() => import("../../map/leaflet-map/LeafletMapWithCommunes"));

export default function PerimeterMap() {
    const [displayMap, setDisplayMap] = useState(false)
    const {enqueueSnackbar} = useSnackbar()
    const atLeast1InjectionPointWithAddressActive = useSelector(atLeast1ActiveInjectionPointsWithAddress)
    const showDensity = useSelector(selectShowCartoDensity)

    function handleDisplayMapClick() {
        if (atLeast1InjectionPointWithAddressActive) {
            setDisplayMap(!displayMap)
        } else {
            enqueueSnackbar("Vous devez avoir au moins 1 point d'injection activé ayant son adresse renseignée.", {variant: "warning"})
        }
    }

    return <>
        <div className={"flex w-full justify-between items-end"}>
            <div>
                <TitleText title={"Vérifiez le périmètre"}/>
                <DescriptionText
                    description={"Visualisez vos sites actifs (dont l'adresse est renseignée) et vérifiez qu'ils rentrent tous dans le périmètre défini. Vous pouvez déplacer le cercle avec votre curseur en drag and drop. Désactivez les sites trop éloignés dans le tableau."}/>
            </div>
            <div className={"w-[256px] h-[62px] px-4 py-2 "}
                 title={'Visualisez et vérifiez le périmètre de vos sites actifs sur une carte.'}>
                <SingleBtnToggle action={handleDisplayMapClick}
                                 Icon={<MapIconSolid className={'h-6 w-6'}/>}
                                 option={displayMap ? 'Cacher la carte' : 'Visualiser le périmètre'}
                                 selected={displayMap}/>
            </div>
        </div>
        {displayMap &&
            <div className={"m-4 z-20"}>
                <Suspense fallback={<p>chargement...</p>}>
                    <LeafletControls/>
                </Suspense>
                {showDensity ?
                    <Suspense fallback={<Loading/>}>
                        <LeafletMapWithCommunes/>
                    </Suspense> :
                    <Suspense fallback={<Loading/>}>
                        <LeafletMap/>
                    </Suspense>
                }
            </div>}
    </>
}
