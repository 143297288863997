import {SGEFetchedCDCStatus} from "../../../../../../../../corelogic/usecases/typology-form/typologyFormReducer";
import {SGEResults} from "../../../../../../../secondary/gateways/APINewSimulationGateway";
export const result4SuccessfullWeeks:SGEResults = {
    failuresByWeeks : [],
    successfullResponseNumber : 4,
    successRate: 1
}
export const result2Success2FailedWeeks:SGEResults = {
    failuresByWeeks : [
        { from : "2023-01-21", to : "2023-01-28", error:{code: "ERROR_1", message: "Tout vas mal."}, retries : "2"},
        {from : "2023-01-14", to : "2023-01-21", error:{code: "ERROR_1", message: "Tout vas mal."}, retries : "2"},

    ],
    successfullResponseNumber : 2,
    successRate: 0.5
}
export const result1Fail1Success2FailWeeks:SGEResults = {
    failuresByWeeks : [
        { from : "2023-01-21", to : "2023-01-28", error:{code: "ERROR_1", message: "Tout vas mal."}, retries : "2"},
        { from : "2023-01-14", to : "2023-01-21", error:{code: "ERROR_1", message: "Tout vas mal."}, retries : "2"},
        { from : "2023-01-01", to : "2023-01-07", error:{code: "ERROR_2", message: "Tout vas très mal."}, retries : "0"},
    ],
    successfullResponseNumber : 1,
    successRate: 0.25
}
export const resultE1E1E2SuccessE2SuccessE2E2E2:SGEResults = {
    failuresByWeeks : [
        { from : "2023-02-25", to : "2023-03-04", error:{code : "ERROR_2", message : "Tout vas mal."}, retries : "2"},
        { from : "2023-02-18", to : "2023-02-25", error:{code : "ERROR_2", message : "Tout vas mal."}, retries : "2"},
        { from : "2023-02-11", to : "2023-02-18", error:{code : "ERROR_2", message : "Tout vas mal."}, retries : "2"},
        { from : "2023-01-28", to : "2023-02-04", error:{code : "ERROR_2", message : "Tout vas mal."}, retries : "2"},
        { from : "2023-01-14", to : "2023-01-21", error:{code : "ERROR_2", message : "Tout vas mal."}, retries : "2"},
        { from : "2023-01-07", to : "2023-01-14", error:{code: "ERROR_1", message: "Tout vas très mal."}, retries : "0"},
        { from : "2023-01-01", to : "2023-01-07", error:{code : "ERROR_1", message : "Tout vas très mal."}, retries : "0"},

    ],
    successfullResponseNumber : 2,
    successRate: 2/7
}

export const  expectedAggregatedFailuresE1E1E2SuccessE2SuccessE2E2E2:SGEFetchedCDCStatus[] = [
    { from : "2023-02-11", to : "2023-03-04", error:{code : "ERROR_2", message : "Tout vas mal."}, retries : "2"},
    { from : "2023-01-28", to : "2023-02-04", error:{code : "ERROR_2", message : "Tout vas mal."}, retries : "2"},
    { from : "2023-01-14", to : "2023-01-21", error:{code : "ERROR_2", message : "Tout vas mal."}, retries : "2"},
    { from : "2023-01-01", to : "2023-01-14", error:{code : "ERROR_1", message : "Tout vas très mal."}, retries : "0"},

]
