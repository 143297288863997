import {InformationCircleIconOrangeOnHover} from "../../../../components/assets/icon/InformationCircleIcon";

const SelfConsumptionIncomeHead = () => <th align={"left"}
                                            className="border-l-2 text-xs  dark:border-zinc-800 py-3 px-4 bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
                                            rowSpan={2}>
    <div className="flex ">
        <div className="flex flex-col"><span className={"italic"}>pour information</span>
            <span className="mr-2"> Gain net autoconsommateur (€)</span></div>

        <div title={'le gain net autoconsommateur est affiché à titre informatif.'}>
                            <InformationCircleIconOrangeOnHover/>
                        </div>
    </div>
</th>
const CumulativeFluxHead = () => <th align={"center"}
                                     className="w-36 py-3 px-4 text-xs text-zinc-500 font-black dark:border-zinc-800 dark:text-zinc-200 border-l-2"
                                     rowSpan={2}>Flux cumulés (€) </th>
const TotalHead = () => <th align={"right"} rowSpan={2} className="w-32 text-gray-500 text-xs leading-normal dark:text-zinc-400 py-3 px-4 ">TOTAL (€)</th>
const NetSavingsHead = () => <th align={"right"} rowSpan={2} className="text-gray-500 text-xs leading-normal dark:text-zinc-400 py-3 px-4 ">Economie nette (€)</th>

const IncomeProducer = ({isOpenOperation}:{isOpenOperation:boolean}) => <th align={"center"} colSpan={isOpenOperation ? 2:1} className="text-xs bg-neutral-100 text-neutral-500 dark:bg-neutral-700  dark:text-neutral-400 py-3 px-4 ">
        <span className="mr-2">Revenu producteur (€)</span>
</th>
const IncomeExcessHead = () => <th align={"center"}
                                   className="py-3 px-4 text-xs bg-neutral-100 text-neutral-500 dark:bg-neutral-700  dark:text-neutral-400">
    <div className="flex items-center justify-center">
        <span className="mr-2">Revenu surplus et taxes (€)</span>
        <div
            title={'Cette part du revenu producteur est composée de la vente de surplus (inflation nulle), si le point de production est en injection TOTALE, du TURPE producteur (inflation à 1.5%) de l’IFER (si la production de la centrale est supérieure à 100kWc, inflation à 1.5%). Cette formule explique la décroissance du revenu producteur au fil des ans.'}>
                            <InformationCircleIconOrangeOnHover/>
                        </div>
    </div>
</th>
const ACIAndACCEconomyHead = () => <th align={"right"} rowSpan={2} className="py-3 px-4 text-gray-500 text-xs leading-normal dark:text-zinc-400">Economie ACI + ACC (€
    TTC)</th>
const BillAvoiding = ({isACI, hasGiftedElectricity}:{isACI:boolean, hasGiftedElectricity:boolean}) => {

    return <th align={"center"}
               className="py-3 px-4 bg-sky-100 text-sky-500 text-xs  dark:bg-sky-900  dark:text-sky-600"
               colSpan={isACI && hasGiftedElectricity ? 2 : 1}>
        Evitement de facture (€)
    </th>
}
const GiftedElectricityHead = () => {

    return <th align={"center"}
               className="py-3 px-4 bg-sky-100 text-sky-500 text-xs  dark:bg-sky-900  dark:text-sky-600">
        Partage gratuit d'électricité (€)
    </th>
}
const YearHead = () => <th align={"center"} className="py-3 px-4 border-r dark:border-zinc-800 text-xs text-gray-500 " rowSpan={2}>Année</th>
const VariableInflationHead = () => <th title={'Inflation du tarif de fourniture du complement (%)'} align={"right"}
                                        className="border-r dark:border-zinc-800 py-3 px-3 max-w-[86px] truncate text-gray-500 text-xs leading-normal dark:text-zinc-400"
                                        rowSpan={2}>Inflation du tarif de fourniture du complement (%)</th>
const CapexAndOpexHead = () => <>
    <th align={"right"} rowSpan={2} className="py-3 px-4 text-gray-500 text-xs leading-normal dark:text-zinc-400">CAPEX (€ HT)</th>
    <th align={"right"} rowSpan={2} className="py-3 px-4 text-gray-500 text-xs leading-normal dark:text-zinc-400">OPEX (€ HT)</th>
</>
const RentHead = () => <th align={"right"} rowSpan={2} className="py-3 px-4 bg-indigo-100 dark:bg-indigo-800 dark:bg-opacity-70 text-indigo-500 text-xs leading-normal dark:text-indigo-400">Loyer (€ HT)</th>
const RoyaltyHead = () =><th align={"right"} rowSpan={2} className="py-3 px-4 bg-indigo-100 dark:bg-indigo-800 dark:bg-opacity-70 text-indigo-500 text-xs leading-normal dark:text-indigo-400">Redevance (€ HT)</th>
const CapexLoanHead = () => <th align={"right"} rowSpan={2} className="py-3 px-4 text-gray-500 text-xs leading-normal dark:text-zinc-400">Intérêt de l'emprunt (€)</th>

const InternalSellingHead = () => <th align={"center"}
                className="py-3 px-4 bg-neutral-100 text-neutral-500 dark:bg-neutral-700 text-xs  dark:text-neutral-400">
    Revenu vente interne (€)
</th>

const ACISavingsHead = () => <th align={"center"}
                                 className="py-3 px-4 text-xs  bg-sky-100 text-sky-500 dark:bg-sky-900  dark:text-sky-600">Economie
    ACI (€)</th>



export {BillAvoiding, ACISavingsHead, InternalSellingHead, NetSavingsHead, SelfConsumptionIncomeHead, CapexAndOpexHead, CapexLoanHead, CumulativeFluxHead,
    ACIAndACCEconomyHead, IncomeExcessHead, IncomeProducer, GiftedElectricityHead, TotalHead, YearHead, RoyaltyHead, RentHead, VariableInflationHead
}