import {useDispatch, useSelector} from "react-redux";
import {push} from "connected-react-router";
import {selectRemainingNumberOfSimulations} from "../../../../../corelogic/usecases/authentication/authenticationSelector";
import {useSnackbar} from "notistack";
import React, {useState} from "react";
import ConfirmModal from "../confirmation-dialog/ConfirmModal";

type EmptyTableType = {
    title: string,
    description: string,
    btnText?:string,
    btnDisabled ?:boolean
}
export default function ({title, description, btnText, btnDisabled}: EmptyTableType) {
    const dispatch = useDispatch()
    const {enqueueSnackbar} = useSnackbar()
    const [showConfirmNewOperation, setShowConfirmNewOperation] = useState(false)

    function redirectToCreationPage(){
        if(!btnDisabled){
            setShowConfirmNewOperation(true)
        }else{
            enqueueSnackbar(`Vous n'avez plus de crédits pour cette organisation. Contactez Enogrid pour en savoir plus.`, {variant: 'warning'})

        }
    }


    function confirmDialog() {
        dispatch(push('/simulation/create'))
    }
    const ConfirmModalDescription = () => <div>
        {"Vous êtes sur le point de démarrer une nouvelle simulation et cela peut avoir un impact sur votre facturation."}
        <ul className="list-disc space-y-2  mt-4 ml-5">
            <li className="leading-relaxed">
                Si vous lancez une nouvelle simulation depuis <strong>votre espace d'étude</strong>, cela sera décompté du nombre d'études en
                simultanée pouvant être réalisée dans le cadre de votre abonnement, <strong>sans surcoût</strong>.
            </li>
            <li className="leading-relaxed">
                Si vous lancez une nouvelle simulation depuis <strong>votre espace de sauvegarde</strong>, conformément à nos conditions
                générales de vente, vous serez facturé d'un montant de
                <span className="font-semibold text-gray-900 dark:text-white">{" 500€ HT"}</span> (facturation trimestrielle).
            </li>
            <li className="leading-relaxed">
                Si vous bénéficiez d'une formule de souscription spécifique ou hors abonnement, veuillez vous référez à vos
                conditions particulières de vente.
            </li>
        </ul>
    </div>


    return (<>
        {
            showConfirmNewOperation &&
            <ConfirmModal
                title={"Information importante : Nouvelle simulation"}
                description={<ConfirmModalDescription/>}
                actionContinue={confirmDialog}
                actionCancel={() => setShowConfirmNewOperation(false)}
            />
        }<div data-cy={"empty-table"} className="bg-slate-50 w-full flex flex-col p-10 items-center dark:bg-zinc-800">
        <div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-gray-800 dark:text-zinc-300" fill="none"
                 viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"/>
            </svg>
            <div className="text-gray-800 dark:text-zinc-300 text-xl py-2 pl-2">{title}</div>
        </div>
        <div className="text-gray-500 dark:text-zinc-400 font-light">{description}
        </div>
        {
            btnText && <button onClick={redirectToCreationPage}
                                 className={!btnDisabled ?"btn-selected bg-secondary mr-6 opacity-80 hover:opacity-100 my-3" :  "btn-selected bg-slate-400 mr-6 opacity-40 my-3"}>{btnText}</button>
        }

    </div></>)
}
