import CheckIcon from "../../icon/CheckIcon";
import React, {useEffect, useState} from "react";
import HomeIcon from "../../icon/HomeIcon";
import ColorSwatchIcon from "../../icon/ColorSwatchIcon";
import {ProductionType} from "../../../../../../../corelogic/models/types/new-simulation/form/ProductionType";
import {SunIcon, SunIconOutlined} from "../../icon/SunIcon";
import RenewableIcon from "../../icon/RenewableIcon";
import {MoonIconOutlined} from "../../icon/MoonIcon";
import {Type} from "../../../../containers/PhysicalResult";

type SelectorInputType = {
    selected: any,
    types: any,
    action(type: any): void,
}

const producerIcon = (prodType ?: ProductionType) => {
    switch(prodType){
        case ProductionType.photovoltaics : return <SunIcon classname={"w-4 h-4"}/>
        default : return <RenewableIcon/>
    }
}
function scopeToIcon(scope : string, prodType ?:ProductionType)  {
    switch(scope){
        case 'consumer': return <div title={"consommateur"} className={" rounded-full p-1 h-6 w-6 text-slate-50 bg-blue-500"}><HomeIcon/></div>
        case 'producer': return <div title={"producteur"} className={" rounded-full p-1 h-6 w-6 text-slate-50  bg-yellow-500"}>{producerIcon(prodType)}</div>
        default: return <div title={"global"} className={"bg-slate-200 rounded-full p-1 h-6 w-6  text-slate-50 bg-gray-600"}><ColorSwatchIcon/></div>
    }
}

export default function ({selected, types, action}: SelectorInputType) {
    const [expand, setExpand] = useState(false)
    const [selectedName, setSelectedName] = useState('')

    useEffect(()=> {
        setSelectedName(types.find((type:Type)=>type.id ===selected.id)?.name ||  '')
    },[selected])
    return <div className="relative inline-block text-left bg-slate-50 dark:bg-zinc-800">
        <button onClick={() => setExpand(!expand)} type="button"
                className="relative w-full bg-white border hover:bg-slate-100 border-gray-200 dark:bg-zinc-800 dark:hover:bg-zinc-700 shadow rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default hover:cursor-pointer sm:text-sm"
                aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
              <span className="flex items-center">
                  {scopeToIcon(selected.scope, selected?.productionType)}
                  <span className="ml-3 block truncate dark:text-zinc-200">{selectedName} </span>
              </span>
            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                             fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd"
                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clipRule="evenodd"/>
                </svg>
              </span>
        </button>

        {expand && <div onMouseLeave={() => setExpand(false)}
                       className="absolute z-50  origin-top-right right-0  mt-1 w-full bg-white  shadow-lg dark:bg-zinc-800   rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                       tabIndex={-1} role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
            <div className="py-1 z-[1] relative  overflow-y-auto" style={{maxHeight:`${Number(window.innerHeight-150)}px`}}role="none">
            {types.map((type: any) => {
                return <div onClick={() => action(type)}
                           className="py-1 z-[1] relative flex items-center block text-gray-900 cursor-default select-none py-2 pl-3 pr-9 dark:hover:bg-zinc-700 hover:cursor-pointer  hover:bg-slate-50">
                        {scopeToIcon(type.scope, type?.productionType)}
                        <span className="font-normal ml-3 block truncate dark:text-zinc-200"> {type.name} </span>
                    <span
                        className={type.id === selected.id ? "text-white absolute inset-y-0 right-0 flex items-center pr-4" : "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"}>
                    {type.id === selected.id && <CheckIcon color={"text-slate-600"}/>}
                    </span>
                </div>
            })}
            </div>

        </div>}
    </div>
}
