import React, {useEffect, useRef, useState} from "react";
import {WrapperCard} from "../wrapper/WrapperCard";
import {useSelector} from "react-redux";
import {
    selectRestitutionData,
    selectRestitutionLoading
} from "../../../../../corelogic/usecases/restitution/restitutionSelectors";
import {formatNumber} from "../../../../../corelogic/usecases/config";
import { selectCapexesWithIds, selectFinancialDuration } from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectFinancialParameter";
import { selectInjectionPoints } from "../../../../../corelogic/usecases/new-simulation/new-simulation-selector/form-data/selectInjectionPointForm";


function ProductionsRecapTable() {
    const restitution = useSelector(selectRestitutionData)
    const restitution_loading = useSelector(selectRestitutionLoading);
    const injectionPoints = useSelector(selectInjectionPoints)
    const duration = useSelector(selectFinancialDuration)
    const capexesWithIds = useSelector(selectCapexesWithIds)
    const prodRecapRef= useRef(null)
    const [injectionPointsIds, setInjectionPointsIds] = useState<string[]>([])
    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
        if (restitution.physics_metrics) {
            setInjectionPointsIds(Object.keys(restitution.physics_metrics.injection_points_metrics))
        }
    }, [restitution])

    useEffect(() => {
        setDataLoaded(restitution.physics_metrics && !restitution_loading)
    }, [restitution_loading, restitution])
    
    const rowStyle = "py-1 px-4 border-r dark:border-zinc-800 whitespace-nowrap"

    const cashFlowData = restitution?.cash_flow_tables?.global_cash_flow_table
    const opexSum = cashFlowData ? cashFlowData.reduce((acc: number, curr: any) => acc + curr["Maintenance (€)"], 0) : 0
    const capexSum = Object.values(capexesWithIds).reduce((acc: number, curr: any) => acc + curr, 0)

    const global_metrics = restitution?.physics_metrics?.global_metrics
    const global_financial_metrics = restitution?.financial_metrics?.global_metrics
    const global_cost_and_gains = restitution?.cost_and_gains?.global_cost_and_gains
    let globalMaximumProductionPower: any = 0
    return <WrapperCard exportAsPNGRef={prodRecapRef}  exportTableId={'prod-recap-table'} title={"Productions"}>
        <div ref={prodRecapRef} className=" bg-white shadow-md rounded dark:bg-zinc-700 overflow-x-auto">
            <table id={'prod-recap-table'} className="w-full table-auto rounded-lg">
                <thead className={'bg-white dark:bg-zinc-700'}>
                <tr className=" text-gray-500 text-xs leading-normal dark:text-zinc-400 border-b dark:border-zinc-800 ">
                    <th align={"center"} rowSpan={2} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">Site</th>
                    <th align={"center"} rowSpan={2} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">Puissance installée (kW)</th>
                    <th align={"center"} colSpan={2} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">Production </th>
                    <th align={"center"} colSpan={3} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">Taux d'autoconsommation (%)</th>
                    <th align={"center"} colSpan={2} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">Surplus</th>
                    <th align={"center"} rowSpan={2} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">CAPEX (€)</th>
                    <th align={"center"} rowSpan={2} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">Somme des OPEX sur {duration} ans (€)</th>
                    <th align={"center"} colSpan={2} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">Revenu vente, surplus & taxes (€) </th>
                    <th align={"center"} rowSpan={2} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">LCOE (c€/kWhHT)</th>
                    <th align={"center"} rowSpan={2} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">TRI (%)</th>
                    <th align={"center"} rowSpan={2} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">TRA (ans)</th>
                    </tr>
                    <tr className=" text-gray-500 text-xs leading-normal dark:text-zinc-400 border-b dark:border-zinc-800 ">
                    <th align={"center"} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">annuelle (kWh)</th>
                    <th align={"center"} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">maximum (kW)</th>
                    <th align={"center"} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">global</th>
                    <th align={"center"} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">ACC</th>
                    <th align={"center"} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">ACI</th>
                    <th align={"center"} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">(kWh)</th>
                    <th align={"center"} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">(%)</th>
                    <th align={"center"} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">1 an </th>
                    <th align={"center"} className="py-1 px-4 font-semibold border-r dark:border-zinc-800">{duration} ans </th>
                    </tr>
                </thead>
                <tbody className="text-gray-500 text-xs font-light dark:text-zinc-400 bg-white dark:bg-zinc-700">
                {dataLoaded && injectionPointsIds.map(ip_id => {
                    const ip = injectionPoints.find(ip => ip.id === ip_id)
                    const ip_physics_metrics = restitution?.physics_metrics.injection_points_metrics[ip_id]
                    const ip_financial_metrics = restitution?.financial_metrics.injection_points_metrics[ip_id]
                    const ip_cost_and_gains = restitution?.cost_and_gains.injection_points_cost_and_gains[ip_id]
                    const ip_total_prod = ip_physics_metrics.prod_acc + ip_physics_metrics.prod_aci + ip_physics_metrics.excess
                    const cashFlowData = restitution.cash_flow_tables.injection_points_cash_flow_tables[ip_id]
                    const ip_opexSum = cashFlowData ? cashFlowData.reduce((acc: number, curr: any) => acc + curr["Maintenance (€)"], 0) : 0
                    globalMaximumProductionPower += ip?.maximumProductionPower
                    return <tr className={' hover:bg-gray-100 dark:hover:bg-zinc-600'} key={ip_id}>
                        <td align={"center"} className={rowStyle + " overflow-hidden overflow-ellipsis max-w-[150px]"}>
                            <div title={ip?.name} className="text-sm dark:text-zinc-400">{ip?.name}</div>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(ip?.maximumProductionPower)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber((ip_physics_metrics.excess + ip_physics_metrics.prod_acc + ip_physics_metrics.prod_aci) / 1000)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(ip_physics_metrics.load_curve_max_power / 1000)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(ip_physics_metrics.aci_autoconsumption_rate, 1)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(ip_physics_metrics.prod_acc / ip_total_prod * 100, 1)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(ip_physics_metrics.prod_aci / ip_total_prod * 100, 1)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span className="text-sm dark:text-zinc-400">{formatNumber(ip_physics_metrics.excess / 1000)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(ip_physics_metrics.excess / ip_total_prod * 100, 1)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(capexesWithIds[ip_id])}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(-ip_opexSum)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(ip_cost_and_gains.costs_and_gains_first_year_saving)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(ip_cost_and_gains.costs_and_gains_saving)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{isNaN(ip_financial_metrics?.lcoe) ? "" : formatNumber(ip_financial_metrics?.lcoe * 100, 1)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{isNaN(ip_financial_metrics?.irr) ? "" : formatNumber(ip_financial_metrics?.irr * 100, 1)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{isNaN(ip_financial_metrics?.dpd) ? "" : formatNumber(ip_financial_metrics?.dpd, 1)}</span>
                        </td>
                    </tr>
                })}
                {dataLoaded &&
                    <tr className={' hover:bg-gray-100 dark:hover:bg-zinc-600 font-semibold bg-green-300 hover:bg-green-400 dark:bg-green-900 dark:hover:bg-green-800'} key={"total_prod_row"}>
                        <td align={"center"} className={rowStyle + " overflow-hidden overflow-ellipsis max-w-[150px]"}>
                            <span className="text-sm dark:text-zinc-400">TOTAL Projet</span>
                        </td>
                        <td align={"center"} className={rowStyle + " overflow-hidden overflow-ellipsis max-w-[150px]"}>
                            <span className="text-sm dark:text-zinc-400">{formatNumber(globalMaximumProductionPower)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber((global_metrics.total_prod) / 1000)}</span>
                        </td>
                        <td className={rowStyle}/>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(global_metrics.aci_autoconsumption_rate, 1)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(global_metrics.total_conso_acc / global_metrics.total_prod * 100, 1)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(global_metrics.total_conso_aci / global_metrics.total_prod * 100, 1)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span className="text-sm dark:text-zinc-400">{formatNumber(global_metrics.excess / 1000)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(global_metrics.excess / global_metrics.total_prod * 100, 1)}</span>
                        </td>
                        <td align={"center"} className="py-1 px-4 border-r dark:border-zinc-800 whitespace-nowrap">
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(capexSum)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span className="text-sm dark:text-zinc-400">{formatNumber(-opexSum)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(global_cost_and_gains.costs_and_gains_first_year_saving)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(global_cost_and_gains.costs_and_gains_saving)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{formatNumber(global_financial_metrics.lcoe * 100, 1)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{isNaN(global_financial_metrics.irr) ? "xx" : formatNumber(global_financial_metrics.irr * 100, 1)}</span>
                        </td>
                        <td align={"center"} className={rowStyle}>
                            <span
                                className="text-sm dark:text-zinc-400">{isNaN(global_financial_metrics.dpd) ? "xx" : formatNumber(global_financial_metrics.dpd, 1)}</span>
                        </td>
                    </tr>}
                {!dataLoaded &&
                    <tr className="border-b border-gray-200 animate-pulse">
                        {[...Array(15)].map(() => {
                            return <td className="py-2 px-4 text-left whitespace-nowrap">
                                <div className="flex items-left">
                                    <span className="font-bold bg-gray-100 h-4 w-full dark:bg-zinc-600"/>
                                </div>
                            </td>
                        })}
                    </tr>
                }
                </tbody>
            </table>
        </div>
    </WrapperCard>
}

export {ProductionsRecapTable}
