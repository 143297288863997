import {SimulationsDisplayGatewayInterface} from "../../../../corelogic/usecases/simulations-display/simulationsDisplayGateway.interface";
import {Simulation} from "../../../../corelogic/models/types/simulations-display/Simulation";
import {State} from "../../../../config/app-config";
import {Organisation} from "../../../../corelogic/models/types/simulations-display/Organisation";
import { LandingSimulationConfig } from "../APISimulationsDisplayGateway";

export class SimulationsDisplayInMemoryGateway implements SimulationsDisplayGatewayInterface {
  getSimulations(skip: number, limit: number, organisationId?: string): LandingSimulationConfig {
    const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'long', year: 'numeric' };
    const organisation_1: Organisation = new Organisation('org1', 'Organisation 1', 155, true);
    const simulations = [new Simulation('simu-inmemory-1', 'Grenoble 2022 PV', new Date().toLocaleDateString('fr', dateOptions), 'Y. Jadot', `Aujourd'hui`, State.SUCCESS, 'social', organisation_1), new Simulation('simu-inmemory-2', 'Ile de France en cogé', '03 avril 2022', 'V. Pecresse', `Hier`, State.SUCCESS, 'estate', organisation_1), new Simulation('simu-inmemory-2', 'Marseille 2023', '01 janvier 2022', 'J. Lasalle', `Il y a 1 semaine`, State.WARNING, 'open', organisation_1)];
    return { simulationsCount: simulations.length, simulations };
  }

  getAllSimulations(): Simulation[] | Simulation[] {
    const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'long', year: 'numeric' };
    const organisation_1: Organisation = new Organisation('org1', 'Organisation 1', 155, true);
    const simulations = [new Simulation('simu-inmemory-1', 'Grenoble 2022 PV', new Date().toLocaleDateString('fr', dateOptions), 'Y. Jadot', `Aujourd'hui`, State.SUCCESS, 'social', organisation_1), new Simulation('simu-inmemory-2', 'Ile de France en cogé', '03 avril 2022', 'V. Pecresse', `Hier`, State.SUCCESS, 'estate', organisation_1), new Simulation('simu-inmemory-2', 'Marseille 2023', '01 janvier 2022', 'J. Lasalle', `Il y a 1 semaine`, State.WARNING, 'open', organisation_1)];
    return simulations;
  }

  duplicateSimulation(simulationId: string): Promise<Simulation> | Simulation {
    const organisation_1: Organisation = new Organisation('org1', 'Organisation 1', 155, true);
    return new Simulation('simu-inmemory-1_(copie)', 'Marseille 2023', '01 janvier 2022', 'J. Lasalle', `Il y a 1 semaine`, State.WARNING, 'social', organisation_1);
  }
}
